import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { healthProgramsPaths } from "../constants";

export const useSetHealthProgramsBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE" | "PARAMETERS_UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Health Programs");

  const healthProgramListBreadcrumb = useMemo(() => {
    return {
      name: t("Programs"),
      route: healthProgramsPaths.list.fullPath,
    };
  }, [t]);

  const setHealthProgramListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setHealthProgramCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [healthProgramListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, healthProgramListBreadcrumb, t]);

  const setHealthProgramUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthProgramListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, healthProgramListBreadcrumb]);

  const setHealthProgramParametersUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthProgramListBreadcrumb, { name }, { name: t("Parameters") }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, healthProgramListBreadcrumb, t]);

  useEffect(() => {
    if (containerType === "LIST") {
      setHealthProgramListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setHealthProgramCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setHealthProgramUpdateContainerBreadcrumb();
    } else if (containerType === "PARAMETERS_UPDATE") {
      setHealthProgramParametersUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setHealthProgramListContainerBreadcrumb,
    setHealthProgramCreateContainerBreadcrumb,
    setHealthProgramUpdateContainerBreadcrumb,
    setHealthProgramParametersUpdateContainerBreadcrumb,
  ]);
};
