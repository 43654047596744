/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramCreateMutationVariables = Types.Exact<{
  input: Types.HealthProgramInput;
}>;


export type HealthProgramCreateMutation = { __typename?: 'Mutation', healthProgramCreate?: { __typename?: 'HealthProgramCRUD', healthProgram?: { __typename?: 'HealthProgram', id: string } | null } | null };


export const HealthProgramCreateDocument = gql`
    mutation HealthProgramCreate($input: HealthProgramInput!) {
  healthProgramCreate(input: $input) {
    healthProgram {
      id
    }
  }
}
    `;
export type HealthProgramCreateMutationFn = Apollo.MutationFunction<HealthProgramCreateMutation, HealthProgramCreateMutationVariables>;

/**
 * __useHealthProgramCreateMutation__
 *
 * To run a mutation, you first call `useHealthProgramCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramCreateMutation, { data, loading, error }] = useHealthProgramCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramCreateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramCreateMutation, HealthProgramCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramCreateMutation, HealthProgramCreateMutationVariables>(HealthProgramCreateDocument, options);
      }
export type HealthProgramCreateMutationHookResult = ReturnType<typeof useHealthProgramCreateMutation>;
export type HealthProgramCreateMutationResult = Apollo.MutationResult<HealthProgramCreateMutation>;
export type HealthProgramCreateMutationOptions = Apollo.BaseMutationOptions<HealthProgramCreateMutation, HealthProgramCreateMutationVariables>;