import RouteItem from "@/shared/components/Root/routeTypes";
import { i18n } from "@toolkit/i18n";
import { AppointmentSchedulingIcon } from "@toolkit/ui";
import { NavigateFunction } from "react-router-dom";
import { AppointmentListContainer } from "../containers";
import { appointmentsPaths, appointmentsRoute } from "./AppointmentsPaths";

export const appointmentsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "appointments-routes",
    text: i18n.t("Appointments", { ns: "admin" }),
    route: appointmentsRoute,
    icon: <AppointmentSchedulingIcon />,
    subItems: [
      {
        id: "appointment-list-route",
        route: appointmentsPaths.list.route,
        fullPath: appointmentsPaths.list.fullPath,
        element: <AppointmentListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
