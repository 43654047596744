import { gql } from '@apollo/client';
import { OptimaPriorRequestNodeFragmentFragmentDoc } from '../../../../../shared/gql/fragments/__generated__/OptimaPriorRequestNodeFragment';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const OptimaPriorRequestsWithKpiDocument = gql `
    query OptimaPriorRequestsWithKpi($fetchRejectedAuth: Boolean = false, $fetchWithoutClaim: Boolean = false, $fetchAll: Boolean = false, $transactionDateInput: TransactionDateFilterInput, $after: String, $before: String, $filter: TransactionPriorRequestFilterInput, $first: Int, $last: Int, $sortBy: TransactionDefaultSortingInput) {
  optimaPriorRequestsRejectedAuth(
    after: $after
    before: $before
    first: $first
    last: $last
    transactionDateInput: $transactionDateInput
  ) @include(if: $fetchRejectedAuth) {
    edges {
      node {
        ...OptimaPriorRequestNodeFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
  optimaPriorRequestsWithoutClaim(
    after: $after
    before: $before
    first: $first
    last: $last
    transactionDateInput: $transactionDateInput
  ) @include(if: $fetchWithoutClaim) {
    edges {
      node {
        ...OptimaPriorRequestNodeFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
  optimaPriorRequests(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) @include(if: $fetchAll) {
    edges {
      node {
        ...OptimaPriorRequestNodeFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${OptimaPriorRequestNodeFragmentFragmentDoc}
${PageInfoFragmentFragmentDoc}`;
/**
 * __useOptimaPriorRequestsWithKpiQuery__
 *
 * To run a query within a React component, call `useOptimaPriorRequestsWithKpiQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaPriorRequestsWithKpiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaPriorRequestsWithKpiQuery({
 *   variables: {
 *      fetchRejectedAuth: // value for 'fetchRejectedAuth'
 *      fetchWithoutClaim: // value for 'fetchWithoutClaim'
 *      fetchAll: // value for 'fetchAll'
 *      transactionDateInput: // value for 'transactionDateInput'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useOptimaPriorRequestsWithKpiQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OptimaPriorRequestsWithKpiDocument, options);
}
export function useOptimaPriorRequestsWithKpiLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OptimaPriorRequestsWithKpiDocument, options);
}
