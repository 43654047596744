/* eslint-disable no-nested-ternary */
import { FC, useState } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@health/queries/types";
import { useHealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation } from "../../gql";

type HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableProps = {
  id: string;
  isEnabled: boolean;
};

export const HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnable: FC<
  HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableProps
> = props => {
  const { id, isEnabled } = props;

  const [enabled, setEnabled] = useState(isEnabled);

  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const [enableHealthProgramNetworkProviderProfessionalOverridingVirtualCall, { loading: isSubmitting }] =
    useHealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation({
      onCompleted: data => {
        if (data?.healthProgramNetworkProviderProfessionalUpdateOverridingVirtualCallEnabled?.entity?.id) {
          setEnabled(
            !!data?.healthProgramNetworkProviderProfessionalUpdateOverridingVirtualCallEnabled?.entity?.overridingVirtualCallEnabled
          );
          succeeded(t("Network Provider Professional updated successfully"));
        } else {
          failed(t("Network Provider Professional update failed"));
        }
      },
      onError: ({ graphQLErrors }) => {
        failed(formatGraphQLError(graphQLErrors));
      },
    });

  const handleChangeToggle = () => {
    enableHealthProgramNetworkProviderProfessionalOverridingVirtualCall({
      variables: { id },
    });
  };

  return (
    <>
      {hasManageHealthProgramsPermission ? (
        <CustomToggleButton
          disabled={isSubmitting}
          isLoading={isSubmitting}
          isMarginDisabled
          checked={enabled}
          onChange={handleChangeToggle}
        />
      ) : enabled ? (
        t("Yes")
      ) : (
        t("No")
      )}
    </>
  );
};
