/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberListActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type HealthProgramMemberListActivationMutation = { __typename?: 'Mutation', healthProgramMemberListUpdateActiveStatus?: { __typename?: 'HealthProgramMemberListCRUD', entity?: { __typename?: 'HealthProgramMemberList', id: string, isActive?: boolean | null } | null } | null };


export const HealthProgramMemberListActivationDocument = gql`
    mutation HealthProgramMemberListActivation($id: ID!) {
  healthProgramMemberListUpdateActiveStatus(id: $id) {
    entity {
      id
      isActive
    }
  }
}
    `;
export type HealthProgramMemberListActivationMutationFn = Apollo.MutationFunction<HealthProgramMemberListActivationMutation, HealthProgramMemberListActivationMutationVariables>;

/**
 * __useHealthProgramMemberListActivationMutation__
 *
 * To run a mutation, you first call `useHealthProgramMemberListActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberListActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramMemberListActivationMutation, { data, loading, error }] = useHealthProgramMemberListActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthProgramMemberListActivationMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramMemberListActivationMutation, HealthProgramMemberListActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramMemberListActivationMutation, HealthProgramMemberListActivationMutationVariables>(HealthProgramMemberListActivationDocument, options);
      }
export type HealthProgramMemberListActivationMutationHookResult = ReturnType<typeof useHealthProgramMemberListActivationMutation>;
export type HealthProgramMemberListActivationMutationResult = Apollo.MutationResult<HealthProgramMemberListActivationMutation>;
export type HealthProgramMemberListActivationMutationOptions = Apollo.BaseMutationOptions<HealthProgramMemberListActivationMutation, HealthProgramMemberListActivationMutationVariables>;