import { useTranslation } from "@toolkit/i18n";
import { FormNumberField, Grid, HintComponent } from "@toolkit/ui";

export const HealthProgramParametersSpecializationsForm = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"generalPractitioners"}
          label={t("G Practitioners")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Speciality Rate Factor for General Practitioners multiplied by the total number of points gained at the end of the month"
                )}
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"specialityPractitioners"}
          label={t("SP")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Speciality Rate Factor for Speciality Practitioners multiplied by the total number of points gained at the end of the month"
                )}
              />
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"consultantPractitioners"}
          label={t("CP")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Speciality Rate Factor for Consultant Practitioners multiplied by the total number of points gained at the end of the month"
                )}
              />
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
