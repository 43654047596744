import { PageWrapper, TableGrid } from "@toolkit/ui";
import { GridProvider } from "@/shared/components";
import { useParams } from "react-router-dom";
import { useHealthProgramNetworkProviderProfessionalListQuery } from "../../gql";
import { IHealthProgramNetworkProviderProfessionalListContainerParams } from "../../types";
import { useSetHealthProgramNetworksProvidersBreadcrumbs } from "../../hooks";
import { useHealthProgramNetworkProviderProfessionalListContainerColumns } from "./useHealthProgramNetworkProviderProfessionalListContainerColumns";

export const HealthProgramNetworkProviderProfessionalListContainer = () => {
  const { networkId, providerId } = useParams<IHealthProgramNetworkProviderProfessionalListContainerParams>();

  useSetHealthProgramNetworksProvidersBreadcrumbs("PROFESSIONAL_LIST", { networkId: networkId!, providerId: providerId! });

  return (
    <GridProvider
      gridName={"healthProgramNetworkProviderProfessionalList"}
      query={useHealthProgramNetworkProviderProfessionalListQuery}
      columns={useHealthProgramNetworkProviderProfessionalListContainerColumns()}
      variables={{ filter: { vendor: [providerId!] }, filterProfessional: { healthProgramNetworks: [networkId!] } }}
      skipCall={!networkId || !providerId}
    >
      <PageWrapper>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
