import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, ShowButton } from "@toolkit/ui";
import React, { useCallback, useMemo } from "react";
import { healthProgramMembersPaths } from "@/pages/HealthProgramMembers/constants";
import { useNavigate } from "react-router-dom";
import { IHealthProgramMemberListListNode } from "../../types";

export const useHealthProgramMembersListsColumns = (): CustomTableColumnProps<IHealthProgramMemberListListNode>[] => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const onShowMembersClick = useCallback(
    (id: string) => {
      navigate(`${healthProgramMembersPaths.list.fullPath}?memberListId=${id}`);
    },
    [navigate]
  );

  return useMemo(
    () => [
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
      },
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
      },
      {
        key: "payer",
        header: t("Payer"),
        accessor: ({ payer }) => pickLocalizedValue(payer?.name, payer?.nameAr),
      },
      {
        key: "members",
        header: t("Members"),
        accessor: ({ id }) => <ShowButton onClick={() => onShowMembersClick(id)} />,
      },
    ],
    [onShowMembersClick, t]
  );
};
