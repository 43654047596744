/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayerGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type PayerGetQuery = { __typename?: 'Query', payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null, contactName?: string | null, contactEmail?: string | null, contactMobileNumber?: string | null, contactPhoneNumber?: string | null, licenseNumber: string, tpo: Types.PayerTpo, type: Types.PayerType, isActive: boolean, logo?: string | null, backgroundImage?: string | null, parent?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } | null };


export const PayerGetDocument = gql`
    query PayerGet($id: ID!) {
  payer(id: $id) {
    id
    name
    nameAr
    contactName
    contactEmail
    contactMobileNumber
    contactPhoneNumber
    licenseNumber
    tpo
    type
    isActive
    logo
    backgroundImage
    parent {
      id
      name
      nameAr
    }
  }
}
    `;

/**
 * __usePayerGetQuery__
 *
 * To run a query within a React component, call `usePayerGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePayerGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePayerGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePayerGetQuery(baseOptions: Apollo.QueryHookOptions<PayerGetQuery, PayerGetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PayerGetQuery, PayerGetQueryVariables>(PayerGetDocument, options);
      }
export function usePayerGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PayerGetQuery, PayerGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PayerGetQuery, PayerGetQueryVariables>(PayerGetDocument, options);
        }
export type PayerGetQueryHookResult = ReturnType<typeof usePayerGetQuery>;
export type PayerGetLazyQueryHookResult = ReturnType<typeof usePayerGetLazyQuery>;
export type PayerGetQueryResult = Apollo.QueryResult<PayerGetQuery, PayerGetQueryVariables>;