import { Box, IconsExplorer } from "@toolkit/ui";
import { FC } from "react";

export const IconExplorerContainer: FC = () => {
  return (
    <Box p={2}>
      <IconsExplorer />
    </Box>
  );
};
