import { zodSchema } from "@health/autocompletes";
import { z } from "zod";

export const healthProgramNetworkUpsertFormSchema = z.object({
  name: z.string().min(1),
  payer: zodSchema.payer,
  categoryCode: z.string().min(1),
  policyNumber: z.string().min(1),
  isActive: z.boolean(),
});

export type IHealthProgramNetworkUpsertFormValues = z.infer<typeof healthProgramNetworkUpsertFormSchema>;

export const healthProgramNetworkUpsertFormDefaultValues: Partial<IHealthProgramNetworkUpsertFormValues> = {
  name: undefined,
  payer: undefined,
  categoryCode: undefined,
  policyNumber: undefined,
  isActive: false,
};
