import { makeStyles } from "@toolkit/ui";

export const usePatientInsuranceInformationStyle = makeStyles()(theme => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  innerWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 4,
  },
  label: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.medium,
  },
}));
