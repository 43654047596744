/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderProfessionalListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.DoctorFilterInput>;
  filterProfessional?: Types.InputMaybe<Types.HealthProgramNetworkProviderProfessionalFilterInput>;
}>;


export type HealthProgramNetworkProviderProfessionalListQuery = { __typename?: 'Query', doctors?: { __typename?: 'DoctorCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'DoctorCountableEdge', node: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null, specializations?: Array<{ __typename?: 'DoctorSpecialization', id: string, code: string } | null> | null, healthProgramNetworkProviderProfessionals?: { __typename?: 'HealthProgramNetworkProviderProfessionalCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramNetworkProviderProfessionalCountableEdge', node: { __typename?: 'HealthProgramNetworkProviderProfessional', id: string, overridingVirtualCallEnabled?: boolean | null, professional?: { __typename?: 'Doctor', user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null } }> } | null } }> } | null };


export const HealthProgramNetworkProviderProfessionalListDocument = gql`
    query HealthProgramNetworkProviderProfessionalList($first: Int, $last: Int, $before: String, $after: String, $filter: DoctorFilterInput, $filterProfessional: HealthProgramNetworkProviderProfessionalFilterInput) {
  doctors(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        user {
          id
          fullName
        }
        specializations {
          id
          code
        }
        healthProgramNetworkProviderProfessionals(
          first: 100
          filter: $filterProfessional
        ) {
          pageInfo {
            endCursor
            hasNextPage
            hasPreviousPage
            startCursor
          }
          edges {
            node {
              id
              overridingVirtualCallEnabled
              professional {
                user {
                  id
                  fullName
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramNetworkProviderProfessionalListQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworkProviderProfessionalListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderProfessionalListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworkProviderProfessionalListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      filterProfessional: // value for 'filterProfessional'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderProfessionalListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworkProviderProfessionalListQuery, HealthProgramNetworkProviderProfessionalListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworkProviderProfessionalListQuery, HealthProgramNetworkProviderProfessionalListQueryVariables>(HealthProgramNetworkProviderProfessionalListDocument, options);
      }
export function useHealthProgramNetworkProviderProfessionalListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworkProviderProfessionalListQuery, HealthProgramNetworkProviderProfessionalListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworkProviderProfessionalListQuery, HealthProgramNetworkProviderProfessionalListQueryVariables>(HealthProgramNetworkProviderProfessionalListDocument, options);
        }
export type HealthProgramNetworkProviderProfessionalListQueryHookResult = ReturnType<typeof useHealthProgramNetworkProviderProfessionalListQuery>;
export type HealthProgramNetworkProviderProfessionalListLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworkProviderProfessionalListLazyQuery>;
export type HealthProgramNetworkProviderProfessionalListQueryResult = Apollo.QueryResult<HealthProgramNetworkProviderProfessionalListQuery, HealthProgramNetworkProviderProfessionalListQueryVariables>;