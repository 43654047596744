import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate, useParams } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { HealthProgram } from "@health/queries/types";
import { useHealthProgramParameterListQuery, useHealthProgramUpdateMutation } from "../../gql";
import { convertHealthProgramParametersFormValuesToBackEndValues } from "../../others";
import { healthProgramsPaths } from "../../constants";
import { useSetHealthProgramsBreadcrumbs } from "../../hooks";
import { IHealthProgramUpdateContainerParams } from "../../types";
import {
  IHealthProgramParametersUpdateFormEvent,
  HealthProgramParametersUpdateForm,
} from "../../forms/HealthProgramParametersUpdate/HealthProgramParametersUpdateForm";

export const HealthProgramParametersUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { healthProgramId } = useParams<IHealthProgramUpdateContainerParams>();

  const { data, loading } = useHealthProgramParameterListQuery({
    variables: { id: healthProgramId! },
    skip: !healthProgramId,
    fetchPolicy: "no-cache",
  });

  const healthProgram = data?.healthProgram as HealthProgram;

  const [updateHealthProgram, { loading: isSubmitting }] = useHealthProgramUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramUpdate?.healthProgram?.id) {
        succeeded(t("Health Program updated successfully"));
        navigate(healthProgramsPaths.list.fullPath);
      } else {
        failed(t("Health Program update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramParametersUpdateFormChange = (event: IHealthProgramParametersUpdateFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramParametersFormValuesToBackEndValues(event.payload.values);

      updateHealthProgram({
        variables: {
          id: healthProgramId!,
          input: {
            code: healthProgram?.code!,
            name: healthProgram?.name!,
            subscriptionPeriod: healthProgram?.subscriptionPeriod,
            ...values,
          },
        },
      });
    }
  };

  useSetHealthProgramsBreadcrumbs("PARAMETERS_UPDATE", healthProgram?.name!);

  return (
    <HealthProgramParametersUpdateForm
      healthProgram={healthProgram}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramParametersUpdateFormChange}
    />
  );
};
