import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useHealthProgramMemberListCreateMutation } from "../../gql";
import { healthProgramMemberListsPaths } from "../../constants";
import { convertHealthProgramMemberListFormValuesToBackEndValues } from "../../others";
import { useSetHealthProgramMemberListsBreadcrumbs } from "../../hooks";
import {
  HealthProgramMemberListUpsertForm,
  IHealthProgramMemberListUpsertFormEvent,
} from "../../forms/HealthProgramMemberListUpsert/HealthProgramMemberListUpsertForm";

export const HealthProgramMemberListCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createHealthProgramMemberList, { loading: isSubmitting }] = useHealthProgramMemberListCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramMemberListCreate?.entity?.id) {
        succeeded(t("Member List created successfully"));
        navigate(healthProgramMemberListsPaths.list.fullPath);
      } else {
        failed(t("Member List creation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramMemberListUpsertFormChange = (event: IHealthProgramMemberListUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramMemberListFormValuesToBackEndValues(event.payload.values);

      createHealthProgramMemberList({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetHealthProgramMemberListsBreadcrumbs("CREATE");

  return (
    <HealthProgramMemberListUpsertForm
      buttonLabel={t("Create")}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramMemberListUpsertFormChange}
    />
  );
};
