import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { HealthProgramMembersLists } from "../../components/HealthProgramMembersLists/HealthProgramMembersLists";

type HealthProgramMembersListsModalProps = {
  id: string;
  membersListsCount: number;
};

export const HealthProgramMembersListsModal: FC<HealthProgramMembersListsModalProps> = props => {
  const { id, membersListsCount } = props;

  const { t } = useTranslation("admin");

  return (
    <CustomDialog type={"info"} button={<ShowButton disabled={!membersListsCount} />} title={t("Health Program Members Lists")}>
      <HealthProgramMembersLists id={id} />
    </CustomDialog>
  );
};
