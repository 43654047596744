import { z } from "zod";

export const healthProgramParametersUpdateFormSchema = z.object({
  normalDemand: z.coerce.number().min(0),
  urgentDemand: z.coerce.number().min(0),
  callRating1: z.coerce.number().min(0),
  callRating2: z.coerce.number().min(0),
  callRating3: z.coerce.number().min(0),
  callRating4: z.coerce.number().min(0),
  callRating5: z.coerce.number().min(0),
  availabilityHours: z.coerce.number().min(0),
  unattendedCallPenalty: z.coerce.number().min(0),
  generalPractitioners: z.coerce.number().min(0),
  specialityPractitioners: z.coerce.number().min(0),
  consultantPractitioners: z.coerce.number().min(0),
});

export type IHealthProgramParametersUpdateFormValues = z.infer<typeof healthProgramParametersUpdateFormSchema>;

export const healthProgramParametersUpdateFormDefaultValues: Partial<IHealthProgramParametersUpdateFormValues> = {
  normalDemand: 10,
  urgentDemand: 15,
  callRating1: 0.4,
  callRating2: 0.6,
  callRating3: 0.8,
  callRating4: 1,
  callRating5: 1.2,
  availabilityHours: 40,
  unattendedCallPenalty: 0.2,
  generalPractitioners: 1,
  specialityPractitioners: 1.5,
  consultantPractitioners: 2,
};
