import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { healthProgramMembersRoute, healthProgramMembersPaths } from "./HealthProgramMembersPaths";
import { HealthProgramMemberListContainer, HealthProgramMemberCreateContainer, HealthProgramMemberUpdateContainer } from "../containers";

export const healthProgramMembersRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "health-program-members-routes",
    text: i18n.t("Members", { ns: "admin" }),
    route: healthProgramMembersRoute,
    subItems: [
      {
        id: "health-program-member-list-route",
        route: healthProgramMembersPaths.list.route,
        fullPath: healthProgramMembersPaths.list.fullPath,
        element: <HealthProgramMemberListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-program-member-create-route",
        route: healthProgramMembersPaths.create.route,
        fullPath: healthProgramMembersPaths.create.fullPath,
        hidden: true,
        element: <HealthProgramMemberCreateContainer />,
      },
      {
        id: "health-program-member-update-route",
        route: healthProgramMembersPaths.update.route,
        fullPath: healthProgramMembersPaths.update.fullPath,
        hidden: true,
        element: <HealthProgramMemberUpdateContainer />,
      },
    ],
  };
};
