import { zodSchema } from "@health/autocompletes";
import { z } from "zod";

export const healthProgramMemberListUpsertFormSchema = z.object({
  name: z.string().min(1),
  payer: zodSchema.payer,
  description: z.string().min(1),
  isActive: z.boolean(),
});

export type IHealthProgramMemberListUpsertFormValues = z.infer<typeof healthProgramMemberListUpsertFormSchema>;

export const healthProgramMemberListUpsertFormDefaultValues: Partial<IHealthProgramMemberListUpsertFormValues> = {
  name: undefined,
  description: undefined,
  payer: undefined,
  isActive: false,
};
