import { useTranslation } from "@toolkit/i18n";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useHealthProgramMemberCreateMutation } from "../../gql";
import { healthProgramMembersPaths } from "../../constants";
import { convertHealthProgramMemberFormValuesToBackEndValues } from "../../others";
import { useSetHealthProgramMembersBreadcrumbs } from "../../hooks";
import {
  HealthProgramMemberUpsertForm,
  IHealthProgramMemberUpsertFormEvent,
} from "../../forms/HealthProgramMemberUpsert/HealthProgramMemberUpsertForm";

export const HealthProgramMemberCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [params] = useSearchParams();
  const memberListId = params?.get("memberListId");

  const [createHealthProgramMember, { loading: isSubmitting }] = useHealthProgramMemberCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramMemberCreate?.entity?.id) {
        succeeded(t("Member created successfully"));
        navigate(healthProgramMembersPaths.list.fullPath);
      } else {
        failed(t("Member creation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramMemberUpsertFormChange = (event: IHealthProgramMemberUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramMemberFormValuesToBackEndValues(event.payload.values);

      createHealthProgramMember({
        variables: {
          input: { ...values, healthProgramMemberLists: memberListId ? [memberListId] : values?.healthProgramMemberLists },
        },
      });
    }
  };

  useSetHealthProgramMembersBreadcrumbs("CREATE");

  return (
    <HealthProgramMemberUpsertForm
      buttonLabel={t("Create")}
      memberListId={memberListId}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramMemberUpsertFormChange}
    />
  );
};
