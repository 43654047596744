import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { ListViewIcon } from "@toolkit/ui";
import { payersPaths, payersRoute } from "./PayersPaths";
import { PayerListContainer, PayerCreateContainer, PayerUpdateContainer } from "../containers";

export const payersRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "payers-routes",
    text: i18n.t("Payers", { ns: "admin" }),
    route: payersRoute,
    icon: <ListViewIcon />,
    subItems: [
      {
        id: "payer-list-route",
        route: payersPaths.list.route,
        fullPath: payersPaths.list.fullPath,
        element: <PayerListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "payer-create-route",
        route: payersPaths.create.route,
        fullPath: payersPaths.create.fullPath,
        hidden: true,
        element: <PayerCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "payer-update-route",
        route: payersPaths.update.route,
        fullPath: payersPaths.update.fullPath,
        hidden: true,
        element: <PayerUpdateContainer />,
        onClick: route => navigate(route),
      },
    ],
  };
};
