import { VisitStatus, Maybe } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, IconButton, useAddToast, useTheme } from "@toolkit/ui";
import React, { FC } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { useVisitCancelMutation } from "../../gql";
import { IVisitCancelFormEvent } from "../../forms/VisitCancel/VisitCancelForm";
import { VisitCancelModal, VisitCancelModalApi } from "../../modals/VisitCancel/VisitCancelModal";

type VisitCancelProps = {
  id: string;
  status?: Maybe<VisitStatus>;
};

export const VisitCancel: FC<VisitCancelProps> = props => {
  const { id, status } = props;

  const { t } = useTranslation("admin");
  const theme = useTheme();
  const { succeeded, failed } = useAddToast();

  const onIconButtonClick = () => {
    if (status === VisitStatus.Canceled) return;

    VisitCancelModalApi.open({ id });
  };

  const [cancelVisit, { loading: isSubmitting }] = useVisitCancelMutation({
    onCompleted: mutationData => {
      if (mutationData?.visitCancelBySupportTeam?.visit?.id) {
        succeeded(t("Visit canceled successfully"));
        VisitCancelModalApi.close();
      } else {
        failed(t("Visit cancellation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
    update: (cache, { data }) => {
      const normalizedId = cache.identify({ id: data?.visitCancelBySupportTeam?.visit?.id, __typename: "Visit" });
      cache.evict({ id: normalizedId });
      cache.gc();
    },
  });

  const onVisitCancelModalChange = (event: IVisitCancelFormEvent) => {
    if (event.type === "SUBMIT") {
      const { reason, otherReason } = event.payload.values;

      const reasonValue = reason?.value;

      cancelVisit({
        variables: {
          id: event.payload.id,
          input: {
            cancellationReason: reasonValue?.text?.toLowerCase() === "other" ? otherReason! : reasonValue?.text!,
          },
        },
      });
    }
  };

  return (
    <Box>
      <VisitCancelModal isLoading={isSubmitting} onChange={onVisitCancelModalChange} />

      <IconButton disabled={status === VisitStatus.Canceled} onClick={onIconButtonClick}>
        <CustomIcon icon={"miniClose"} height={30} width={30} viewBox={"0 5 20 10"} color={theme.palette.error.main} />
      </IconButton>
    </Box>
  );
};
