import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { HealthProgramNetworkProviderServices } from "../../components/HealthProgramNetworkProviderServices/HealthProgramNetworkProviderServices";

type HealthProgramNetworkProviderServicesModalProps = {
  services: string[];
};

export const HealthProgramNetworkProviderServicesModal: FC<HealthProgramNetworkProviderServicesModalProps> = props => {
  const { services } = props;

  const { t } = useTranslation("admin");

  return (
    <CustomDialog type={"info"} button={<ShowButton disabled={!services || !services?.length} />} title={t("Network Provider Services")}>
      <HealthProgramNetworkProviderServices services={services} />
    </CustomDialog>
  );
};
