import { Payer, PayerInput, PayerType, PayerTypeEnum, Tpo } from "@health/queries/types";
import { payerTpoOptionsMap, payerTypeOptionsMap } from "@health/enum-options";
import { IPayerUpsertFormValues } from "../forms/PayerUpsert/PayerUpsertFormSchema";
import { createPayerAutocompleteOption } from "@health/autocompletes";

export const convertPayerToFormValues = (payer: Payer): IPayerUpsertFormValues => {
  return {
    name: payer?.name,
    nameAr: payer?.nameAr,
    contactName: payer?.contactName!,
    contactEmail: payer?.contactEmail!,
    contactMobileNumber: payer?.contactMobileNumber,
    contactPhoneNumber: payer?.contactPhoneNumber,
    licenseNumber: payer?.licenseNumber,
    tpo: payerTpoOptionsMap[payer?.tpo],
    type: payerTypeOptionsMap[payer?.type],
    parent: payer?.parent ? createPayerAutocompleteOption(payer?.parent) : undefined,
    logo: payer?.logo ? payer.logo : undefined,
    backgroundImage: payer?.backgroundImage ? payer.backgroundImage : undefined,
    isActive: payer?.isActive,
  };
};

export const convertPayerFormValuesToBackEndValues = (values: IPayerUpsertFormValues): PayerInput => {
  return {
    name: values?.name,
    nameAr: values?.nameAr,
    contactName: values?.contactName,
    contactEmail: values?.contactEmail,
    contactMobileNumber: values?.contactMobileNumber,
    contactPhoneNumber: values?.contactPhoneNumber,
    licenseNumber: values?.licenseNumber,
    tpo: values?.tpo?.value! as unknown as Tpo,
    type: values?.type?.value! as unknown as PayerTypeEnum,
    parentId: values?.type?.value === PayerType.Tpa && values?.parent?.value?.id ? values?.parent?.value?.id : undefined,
    logo: values?.logo,
    backgroundImage: values?.backgroundImage,
    isActive: values?.isActive,
  };
};
