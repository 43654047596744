import { PatientIdType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const patientIdTypeOptionsMap = {
    [PatientIdType.NationalCard]: {
        key: PatientIdType.NationalCard,
        get label() {
            return i18n.t("National Card", { ns: "domains" });
        },
        value: PatientIdType.NationalCard,
    },
    [PatientIdType.Passport]: {
        key: PatientIdType.Passport,
        get label() {
            return i18n.t("Passport", { ns: "domains" });
        },
        value: PatientIdType.Passport,
    },
    [PatientIdType.ResidentCard]: {
        key: PatientIdType.ResidentCard,
        get label() {
            return i18n.t("Resident Card", { ns: "domains" });
        },
        value: PatientIdType.ResidentCard,
    },
};
export const patientIdTypeOptions = Object.values(patientIdTypeOptionsMap);
