import { FormNumberField, Grid, HintComponent } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const HealthProgramParametersCallRatingsForm = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"callRating1"}
          label={t("Rating 1")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 0 to 1/5"
                )}
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"callRating2"}
          label={t("Rating 2")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 1 to 2/5"
                )}
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"callRating3"}
          label={t("Rating 3")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 2 to 3/5"
                )}
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"callRating4"}
          label={t("Rating 4")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 3 to 4/5"
                )}
              />
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"callRating5"}
          label={t("Rating 5")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: (
              <HintComponent
                title={t(
                  "Overall care quality rating factor to be multiplied by the total number of points gained at the end of the month if the average monthly rating of the doctor is from 4 to 5/5"
                )}
              />
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
