import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { HealthProgramNetwork } from "@health/queries/types";
import { IHealthProgramNetworkUpdateContainerParams } from "../../types";
import { useNavigate, useParams } from "react-router-dom";
import { useHealthProgramNetworkGetQuery, useHealthProgramNetworkUpdateMutation } from "../../gql";
import { healthProgramNetworksPaths } from "../../constants";
import { convertHealthProgramNetworkFormValuesToBackEndValues } from "../../others";
import { useSetHealthProgramNetworksBreadcrumbs } from "../../hooks";
import {
  HealthProgramNetworkUpsertForm,
  IHealthProgramNetworkUpsertFormEvent,
} from "../../forms/HealthProgramNetworkUpsert/HealthProgramNetworkUpsertForm";

export const HealthProgramNetworkUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { networkId } = useParams<IHealthProgramNetworkUpdateContainerParams>();

  const { data, loading } = useHealthProgramNetworkGetQuery({
    variables: {
      filter: {
        ids: [networkId!],
      },
    },
    skip: !networkId,
    fetchPolicy: "no-cache",
  });

  const healthProgramNetwork = data?.healthProgramNetworks?.edges?.[0]?.node as HealthProgramNetwork;

  const [updateHealthProgramNetwork, { loading: isSubmitting }] = useHealthProgramNetworkUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramNetworkUpdate?.entity?.id) {
        succeeded(t("Network updated successfully"));
        navigate(healthProgramNetworksPaths.list.fullPath);
      } else {
        failed(t("Network update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramNetworkUpsertFormChange = (event: IHealthProgramNetworkUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramNetworkFormValuesToBackEndValues(event.payload.values);

      updateHealthProgramNetwork({
        variables: {
          id: networkId!,
          input: values,
        },
      });
    }
  };

  useSetHealthProgramNetworksBreadcrumbs("UPDATE", healthProgramNetwork?.name!);

  return (
    <HealthProgramNetworkUpsertForm
      buttonLabel={t("Update")}
      healthProgramNetwork={healthProgramNetwork}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramNetworkUpsertFormChange}
    />
  );
};
