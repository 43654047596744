/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from './Address';
export type AdminVendorFragmentFragment = { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null, description?: string | null, isActive: boolean, logo?: string | null, deliveryPrice: number, backGroundImage?: string | null, commercialRegistrationNumber: string, ownerName: string, nationalId: string, contactMobileNumber?: string | null, contactPhoneNumber?: string | null, tradeName: string, taxLicenseNumber: string, isVip: boolean, hasMultipleBranches: boolean, enableVisitDetails: boolean, isIntegrated: boolean, editLikeEnabled: boolean, notifyByEmail: boolean, created: any, deliveryMinFrom: number, deliveryMinTo: number, priceRange?: Types.PriceRangeEnum | null, type?: Types.VendorType | null, hasOwnDrivers: boolean, address?: { __typename: 'Address', id: string, area: string, areaAr: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null } | null, bankInfo?: { __typename?: 'VendorBankInfo', bankName?: string | null, accountNumber?: string | null, iban?: string | null, accountName?: string | null } | null, managersContactInfo?: { __typename?: 'VendorManagersContactInfo', id: string, generalManagerEmail?: string | null, purchasingManagerName?: string | null, purchasingManagerMobileNumber?: string | null, purchasingManagerEmail?: string | null, financialManagerName?: string | null, financialManagerEmail?: string | null, financialManagerMobileNumber?: string | null } | null };

export const AdminVendorFragmentFragmentDoc = gql`
    fragment AdminVendorFragment on Vendor {
  id
  name
  nameAr
  description
  isActive
  logo
  deliveryPrice
  backGroundImage
  commercialRegistrationNumber
  ownerName
  nationalId
  contactMobileNumber
  contactPhoneNumber
  tradeName
  taxLicenseNumber
  isVip
  hasMultipleBranches
  enableVisitDetails
  isIntegrated
  editLikeEnabled
  notifyByEmail
  created
  address {
    ...AddressFragment
  }
  deliveryMinFrom
  deliveryMinTo
  priceRange
  type
  bankInfo {
    bankName
    accountNumber
    iban
    accountName
  }
  managersContactInfo {
    id
    generalManagerEmail
    purchasingManagerName
    purchasingManagerMobileNumber
    purchasingManagerEmail
    financialManagerName
    financialManagerEmail
    financialManagerMobileNumber
  }
  hasOwnDrivers
}
    ${AddressFragmentFragmentDoc}`;