import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { useHealthProgramCreateMutation } from "../../gql";
import { healthProgramsPaths } from "../../constants";
import { useSetHealthProgramsBreadcrumbs } from "../../hooks";
import { convertHealthProgramCreateFormValuesToBackEndValues } from "../../others";
import { IHealthProgramUpsertFormEvent, HealthProgramUpsertForm } from "../../forms/HealthProgramUpsert/HealthProgramUpsertForm";

export const HealthProgramCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createHealthProgram, { loading: isSubmitting }] = useHealthProgramCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramCreate?.healthProgram?.id) {
        succeeded(t("Health Program created successfully"));
        navigate(healthProgramsPaths.list.fullPath);
      } else {
        failed(t("Health Program creation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramUpsertFormChange = (event: IHealthProgramUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramCreateFormValuesToBackEndValues(event.payload.values);

      createHealthProgram({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetHealthProgramsBreadcrumbs("CREATE");

  return <HealthProgramUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onHealthProgramUpsertFormChange} />;
};
