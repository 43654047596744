import { H_OrderDirection, HealthProgramNetworkSortingField } from "@health/queries/types";
import { PageWrapper, TableGrid } from "@toolkit/ui";
import { GridProvider } from "shared/components";
import { useHealthProgramNetworkListQuery } from "../../gql";
import { useHealthProgramNetworksColumns } from "./useHealthProgramNetworksColumns";
import { FC } from "react";

type HealthProgramNetworksProps = {
  id: string;
};

export const HealthProgramNetworks: FC<HealthProgramNetworksProps> = props => {
  const { id } = props;

  return (
    <GridProvider
      gridName={"healthProgramNetworks"}
      query={useHealthProgramNetworkListQuery}
      columns={useHealthProgramNetworksColumns()}
      filterInput={{
        healthPrograms: [id],
      }}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramNetworkSortingField.Created,
        },
      }}
      skipCall={!id}
    >
      <PageWrapper>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
