import { PermissionEnum } from "@health/queries/types";
import { hasPermission } from "@toolkit/core";
import { useNavigate, useParams } from "react-router-dom";
import { GridProvider } from "shared/components/GridProvider/GridProvider";
import { useHealthProgramNetworkProviderListQuery } from "../../gql";
import { healthProgramNetworksPaths } from "../../constants";
import { IHealthProgramNetworkProviderNode, IHealthProgramNetworkProviderListContainerParams } from "../../types";
import { useSetHealthProgramNetworksProvidersBreadcrumbs } from "../../hooks";
import { HealthProgramNetworkProviderListPageWrapper } from "../../components/HealthProgramNetworkProviderListPageWrapper/HealthProgramNetworkProviderListPageWrapper";
import { useHealthProgramNetworkProviderListContainerColumns } from "./useHealthProgramNetworkProviderListContainerColumns";

export const HealthProgramNetworkProviderListContainer = () => {
  const navigate = useNavigate();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const { networkId } = useParams<IHealthProgramNetworkProviderListContainerParams>();

  const onEditRowClick = (item: IHealthProgramNetworkProviderNode) => {
    navigate(healthProgramNetworksPaths.providerUpdate.fullPathWithParams({ networkId: networkId!, providerId: item?.id }));
  };

  useSetHealthProgramNetworksProvidersBreadcrumbs("LIST", { networkId: networkId! });

  return (
    <GridProvider
      gridName={"healthProgramNetworkProviderList"}
      query={useHealthProgramNetworkProviderListQuery}
      columns={useHealthProgramNetworkProviderListContainerColumns({ networkId: networkId! })}
      filterInput={{ healthProgramNetworks: [networkId] }}
      tableAction={{
        isEditVisible: hasManageHealthProgramsPermission,
        onEditRow: onEditRowClick,
      }}
      hideFilterInput
      skipCall={!networkId}
    >
      <HealthProgramNetworkProviderListPageWrapper networkId={networkId!} />
    </GridProvider>
  );
};
