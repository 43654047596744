import { ActionFieldType, AppointmentFrequencyType, AppointmentPaymentType, AppointmentStatus, AppointmentType, AppRoleTypes, AssigneeType, CallbackRequestStatus, CareType, ChartType, CodeSystemCode, ConditionOperation, Coverage, CoverageByType, CustomerSupportOrderStatusEnum, DoctorSeniorityEnum, DestinationType, FieldStandard, GuidedCareActivityType, GuidedCareJourneyItemSource, GuidedCareType, HealthConditionType, HealthDocumentType, HealthProgramBenefitOptionCode, HealthProgramOptionType, HealthProgramSubscriptionPeriod, HealthProgramType, MarketplaceApprovalStatus, MarketplaceDiscountType, MarketplaceOrderStatus, MarketplaceOrderType, MedicalFormCategory, MedicalFormType, MedicalMessageCategory, MedicalMessageType, Operator, OptimaPriority, OrderStatusEnum, PatientIdType, PayerTpo, PayerType, PaymentSource, PaymentStatus, PaymentType, PlanPeriod, Priority, ProviderGuidedCareTeamStatus, QuestionType, RequestedBy, Source, TaskDefinitionStatus, TaskWorkflowType, TeamMemberPosition, TemplateFieldType, UserGender, UserStatus, VendorTypeEnum, VendorUserTypes, VisitDiagnosisType, VisitStatus, VisitType, } from "@health/queries/types";
import { createZodAutocomplete } from "@toolkit/core";
import { YesNo } from "../enums";
import { PatientEligibilityBenefitsEnum } from "../enums/PatientEligibilityBenefits/type";
export const zodEnumSchema = {
    actionFieldType: createZodAutocomplete(ActionFieldType),
    appointmentFrequencyType: createZodAutocomplete(AppointmentFrequencyType),
    appointmentPaymentType: createZodAutocomplete(AppointmentPaymentType),
    appointmentStatus: createZodAutocomplete(AppointmentStatus),
    appointmentType: createZodAutocomplete(AppointmentType),
    appRoleType: createZodAutocomplete(AppRoleTypes),
    assigneeType: createZodAutocomplete(AssigneeType),
    callbackRequestStatus: createZodAutocomplete(CallbackRequestStatus),
    careType: createZodAutocomplete(CareType),
    chartType: createZodAutocomplete(ChartType),
    systemCode: createZodAutocomplete(CodeSystemCode),
    conditionOperation: createZodAutocomplete(ConditionOperation),
    coverage: createZodAutocomplete(Coverage),
    coverageByType: createZodAutocomplete(CoverageByType),
    customerSupportOrderStatus: createZodAutocomplete(CustomerSupportOrderStatusEnum),
    doctorSeniority: createZodAutocomplete(DoctorSeniorityEnum),
    destinationType: createZodAutocomplete(DestinationType),
    fieldStandard: createZodAutocomplete(FieldStandard),
    guidedCareActivityType: createZodAutocomplete(GuidedCareActivityType),
    guidedCareJourneyItemSource: createZodAutocomplete(GuidedCareJourneyItemSource),
    guidedCareType: createZodAutocomplete(GuidedCareType),
    healthConditionType: createZodAutocomplete(HealthConditionType),
    healthDocumentType: createZodAutocomplete(HealthDocumentType),
    healthProgramBenefitOptionCode: createZodAutocomplete(HealthProgramBenefitOptionCode),
    healthProgramOptionType: createZodAutocomplete(HealthProgramOptionType),
    healthProgramSubscriptionPeriod: createZodAutocomplete(HealthProgramSubscriptionPeriod),
    healthProgramType: createZodAutocomplete(HealthProgramType),
    marketplaceApprovalStatus: createZodAutocomplete(MarketplaceApprovalStatus),
    marketplaceDiscountType: createZodAutocomplete(MarketplaceDiscountType),
    marketplaceOrderStatus: createZodAutocomplete(MarketplaceOrderStatus),
    marketplaceOrderType: createZodAutocomplete(MarketplaceOrderType),
    medicalFormCategory: createZodAutocomplete(MedicalFormCategory),
    medicalFormType: createZodAutocomplete(MedicalFormType),
    medicalMessageCategory: createZodAutocomplete(MedicalMessageCategory),
    medicalMessageType: createZodAutocomplete(MedicalMessageType),
    operator: createZodAutocomplete(Operator),
    optimaPriority: createZodAutocomplete(OptimaPriority),
    orderStatus: createZodAutocomplete(OrderStatusEnum),
    patientIdType: createZodAutocomplete(PatientIdType),
    payerTpo: createZodAutocomplete(PayerTpo),
    payerType: createZodAutocomplete(PayerType),
    paymentSource: createZodAutocomplete(PaymentSource),
    paymentStatus: createZodAutocomplete(PaymentStatus),
    paymentType: createZodAutocomplete(PaymentType),
    planPeriod: createZodAutocomplete(PlanPeriod),
    priority: createZodAutocomplete(Priority),
    providerGuidedCareTeamStatus: createZodAutocomplete(ProviderGuidedCareTeamStatus),
    patientEligibilityBenefits: createZodAutocomplete(PatientEligibilityBenefitsEnum),
    questionType: createZodAutocomplete(QuestionType),
    requestedBy: createZodAutocomplete(RequestedBy),
    source: createZodAutocomplete(Source),
    taskDefinitionStatus: createZodAutocomplete(TaskDefinitionStatus),
    taskWorkflowType: createZodAutocomplete(TaskWorkflowType),
    teamMemberPosition: createZodAutocomplete(TeamMemberPosition),
    templateFieldType: createZodAutocomplete(TemplateFieldType),
    userGender: createZodAutocomplete(UserGender),
    userStatus: createZodAutocomplete(UserStatus),
    vendorType: createZodAutocomplete(VendorTypeEnum),
    vendorUserType: createZodAutocomplete(VendorUserTypes),
    visitDiagnosisType: createZodAutocomplete(VisitDiagnosisType),
    visitStatus: createZodAutocomplete(VisitStatus),
    visitType: createZodAutocomplete(VisitType),
    yesNo: createZodAutocomplete(YesNo),
};
