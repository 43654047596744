import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const HealthProgramBenefitsGroupsAutocompleteDocument = gql `
    query HealthProgramBenefitsGroupsAutocomplete($filter: BenefitFilterInput) {
  healthProgramBenefitGroups(filter: $filter) {
    id
    name
  }
}
    `;
/**
 * __useHealthProgramBenefitsGroupsAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthProgramBenefitsGroupsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramBenefitsGroupsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramBenefitsGroupsAutocompleteQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramBenefitsGroupsAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(HealthProgramBenefitsGroupsAutocompleteDocument, options);
}
export function useHealthProgramBenefitsGroupsAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(HealthProgramBenefitsGroupsAutocompleteDocument, options);
}
