/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation = { __typename?: 'Mutation', healthProgramNetworkProviderProfessionalUpdateOverridingVirtualCallEnabled?: { __typename?: 'HealthProgramNetworkProviderProfessionalCRUD', entity?: { __typename?: 'HealthProgramNetworkProviderProfessional', id: string, overridingVirtualCallEnabled?: boolean | null } | null } | null };


export const HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableDocument = gql`
    mutation HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnable($id: ID!) {
  healthProgramNetworkProviderProfessionalUpdateOverridingVirtualCallEnabled(
    id: $id
  ) {
    entity {
      id
      overridingVirtualCallEnabled
    }
  }
}
    `;
export type HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationFn = Apollo.MutationFunction<HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation, HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationVariables>;

/**
 * __useHealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation__
 *
 * To run a mutation, you first call `useHealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation, { data, loading, error }] = useHealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation, HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation, HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationVariables>(HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableDocument, options);
      }
export type HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationHookResult = ReturnType<typeof useHealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation>;
export type HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationResult = Apollo.MutationResult<HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation>;
export type HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationOptions = Apollo.BaseMutationOptions<HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutation, HealthProgramNetworkProviderProfessionalOverridingVirtualCallEnableMutationVariables>;