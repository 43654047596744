import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetClinicianCredentialsDocument = gql `
    query GetClinicianCredentials($destinationType: DestinationType!, $user: ID!) {
  getClinicianCredentials(destinationType: $destinationType, user: $user) {
    license
    userName
  }
}
    `;
/**
 * __useGetClinicianCredentialsQuery__
 *
 * To run a query within a React component, call `useGetClinicianCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClinicianCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClinicianCredentialsQuery({
 *   variables: {
 *      destinationType: // value for 'destinationType'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useGetClinicianCredentialsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetClinicianCredentialsDocument, options);
}
export function useGetClinicianCredentialsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetClinicianCredentialsDocument, options);
}
