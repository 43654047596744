import { Grid, ImageUploadController } from "@toolkit/ui";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { IPayerUpsertFormValues } from "../PayerUpsert/PayerUpsertFormSchema";

export const PayerImagesForm = () => {
  const { t } = useTranslation("admin");

  const { accessToken } = useOidcUserProfile();

  const form = useCustomFormContext<IPayerUpsertFormValues>();

  const {
    control,
    formState: { errors },
  } = form;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageUploadController
          name={"logo"}
          control={control}
          label={t("Logo")}
          validationRation={true}
          error={!!errors?.logo?.message}
          helperText={errors?.logo?.message}
          token={accessToken!}
        />
      </Grid>

      <Grid item xs={12}>
        <ImageUploadController
          name={"backgroundImage"}
          control={control}
          label={t("Background Image")}
          validationRation={true}
          error={!!errors?.backgroundImage?.message}
          helperText={errors?.backgroundImage?.message}
          token={accessToken!}
        />
      </Grid>
    </Grid>
  );
};
