import { makeStyles } from "@toolkit/ui";

export const useHealthProgramNetworkProviderServicesStyle = makeStyles()(theme => ({
  list: {
    listStyleType: "disc",
    paddingInline: 24,
    fontSize: theme.mixins.fonts.fontSize.xxl,
  },
  listItem: {
    display: "list-item",
    paddingLeft: 0,
  },
  listItemText: {
    fontSize: theme.mixins.fonts.fontSize.md,
  },
}));
