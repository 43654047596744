import { gql } from '@apollo/client';
import { PageInfoFragmentFragmentDoc } from '../../../../AdminManagedLists/gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const OptimaClaimsListDocument = gql `
    query OptimaClaimsList($first: Int, $after: String, $before: String, $filter: TransactionClaimFilterInput, $last: Int, $sortBy: TransactionClaimSortingInput) {
  optimaClaims(
    first: $first
    after: $after
    before: $before
    filter: $filter
    last: $last
    sortBy: $sortBy
  ) {
    edges {
      node {
        id
        transactionId
        transactionDate
        transactionType
        encounterType
        encounterEndType
        encounterStart
        encounterStartType
        encounterEnd
        patientId
        memberId
        emiratesIDNumber
        facilityId
        payerId
        receiverId
        gross
        net
        source
        remittance {
          id
          dateSettlement
          status
          source
          paymentReference
        }
        resubmissionAttachment
        resubmissionComment
        resubmissionRelatedClaimId
        resubmissionType
        submissionCycle
        Branch {
          nameAr
          name
        }
        encounterTypeLookup {
          name
          type
        }
        provider {
          name
          id
        }
        sender {
          name
          id
        }
        receiver {
          id
          name
        }
        payer {
          id
          name
        }
        encounterEndTypeLookup {
          name
        }
        encounterStartTypeLookup {
          name
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
}
    ${PageInfoFragmentFragmentDoc}`;
/**
 * __useOptimaClaimsListQuery__
 *
 * To run a query within a React component, call `useOptimaClaimsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaClaimsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaClaimsListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useOptimaClaimsListQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OptimaClaimsListDocument, options);
}
export function useOptimaClaimsListLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OptimaClaimsListDocument, options);
}
