import React, { FC } from "react";
import { SvgIcon, SvgIconProps } from "../../base/mui";

export const DefaultImage: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      xmlns='http://www.w3.org/2000/svg'
      version='1.1'
      id='svg2'
      xmlSpace='preserve'
      width='310.66666'
      height='312'
      viewBox='0 0 310.66666 312'
      {...props}
    >
      <defs id='defs6'>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath18'>
          <path d='M 0,234 H 233 V 0 H 0 Z' id='path16' />
        </clipPath>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath26'>
          <path d='M 6.55367,228.618 H 226.875 V 5.51051 H 6.55367 Z' id='path24' />
        </clipPath>
        <linearGradient
          x1='0'
          y1='0'
          x2='1'
          y2='0'
          gradientUnits='userSpaceOnUse'
          gradientTransform='matrix(-44.508861,199.48941,199.48941,44.508861,141.20203,-14.786816)'
          spreadMethod='pad'
          id='linearGradient56'
        >
          <stop stopOpacity={1} stopColor='#913264' offset='0' id='stop48' />
          <stop stopOpacity={1} stopColor='#2b3078' offset='0.54753492' id='stop50' />
          <stop stopOpacity={1} stopColor='#2b3078' offset='0.732317' id='stop52' />
          <stop stopOpacity={1} stopColor='#2b3078' offset='1' id='stop54' />
        </linearGradient>
        <clipPath clipPathUnits='userSpaceOnUse' id='clipPath66'>
          <path d='M 0,234 H 233 V 0 H 0 Z' id='path64' />
        </clipPath>
      </defs>
      <g id='g10' transform='matrix(1.3333333,0,0,-1.3333333,0,312)'>
        <g id='g12'>
          <g id='g14' clipPath='url(#clipPath18)'>
            <g id='g20'>
              <g id='g22' />
              <g id='g34'>
                <g clipPath='url(#clipPath26)' opacity='0.100006' id='g32'>
                  <g transform='translate(147.6036,7.7798)' id='g30'>
                    <path
                      d='m 0,0 c -25.957,-3.68 -50.763,-3.323 -76.452,4.344 -25.461,7.677 -51.735,22.446 -60.85,44.336 -8.877,21.92 -0.665,50.823 10.871,75.46 10.821,24.082 26.393,45.724 45.794,63.617 19.866,18.677 45.565,35.677 68.675,32.751 23.011,-3.045 43.443,-26.026 58.599,-48.749 14.828,-21.047 25.034,-44.991 29.974,-70.263 4.741,-25.332 4.096,-53.57 -9.83,-71.582 C 52.995,11.803 25.927,3.898 0,0'
                      fill='#2b3078'
                      fillOpacity={1}
                      fillRule='nonzero'
                      stroke='none'
                      id='path28'
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id='g36'>
          <g id='g38'>
            <g id='g44'>
              <g id='g46'>
                <path
                  d='M 105.083,207.132 C 81.398,197.6 59.894,183.377 41.863,165.316 v 0 C 22.988,147.006 5.64,123.678 6.136,100.815 v 0 C 6.87,77.904 25.566,55.527 45.949,39.866 v 0 C 66.54,24.135 88.866,15.308 113.593,9.724 v 0 l 0.01,-0.01 c 24.777,-5.366 51.953,-7.508 70.64,4.146 v 0 c 18.775,11.525 29.031,36.827 33.425,61.356 v 0 c 4.205,24.538 3.174,49.691 -3.005,73.803 v 0 c -5.912,25.739 -16.505,53.521 -36.262,64.173 v 0 c -7.163,3.808 -15.514,5.394 -24.408,5.394 v 0 c -15.799,0 -33.311,-5.006 -48.91,-11.454'
                  fill='url(#linearGradient56)'
                  stroke='none'
                  id='path58'
                />
              </g>
            </g>
          </g>
        </g>
        <g id='g60'>
          <g id='g62' clipPath='url(#clipPath66)'>
            <g id='g68' transform='translate(139.6093,80.656)'>
              <path
                d='m 0,0 c -16.157,0 -29.25,13.092 -29.25,29.25 0,16.157 13.093,29.249 29.25,29.249 16.157,0 29.25,-13.092 29.25,-29.249 C 29.25,13.092 16.157,0 0,0 m 0,71.007 c -23.061,0 -41.747,-18.697 -41.747,-41.747 0,-23.051 18.696,-41.747 41.747,-41.747 23.051,0 41.747,18.696 41.747,41.747 0,23.06 -18.696,41.747 -41.747,41.747'
                fill='#ffffff'
                fillOpacity={1}
                fillRule={"nonzero"}
                stroke='none'
                id='path70'
              />
            </g>
            <g id='g72' transform='translate(81.0801,71.769)'>
              <path
                d='m 0,0 v 0 c -4.205,-0.099 -7.697,3.233 -7.806,7.439 v 43.959 c 0.228,4.314 3.908,7.617 8.223,7.389 3.987,-0.208 7.181,-3.402 7.389,-7.389 V 7.439 C 7.707,3.233 4.205,-0.099 0,0 m 0,82.591 c 4.9,0.169 9.016,-3.679 9.175,-8.579 v -0.169 c 0.039,-4.8 -3.819,-8.728 -8.62,-8.758 -0.188,0 -0.376,0 -0.555,0.01 -4.791,-0.268 -8.897,3.392 -9.175,8.183 -0.01,0.188 -0.019,0.377 -0.01,0.565 0.04,4.87 4.017,8.788 8.887,8.758 H 0'
                fill='#ffffff'
                fillOpacity={1}
                fillRule={"nonzero"}
                stroke='none'
                id='path74'
              />
            </g>
            <g id='g76' transform='translate(139.5994,85.2285)'>
              <path
                d='m 0,0 c -13.628,0 -24.667,11.049 -24.667,24.667 0,13.628 11.049,24.668 24.667,24.668 13.628,0 24.667,-11.05 24.667,-24.668 C 24.667,11.049 13.618,0 0,0 m 0.198,55.028 c -16.871,0 -30.549,-13.678 -30.549,-30.549 0,-16.872 13.678,-30.549 30.549,-30.549 16.872,0 30.549,13.677 30.549,30.549 0,16.871 -13.677,30.549 -30.549,30.549'
                fill='#ffffff'
                fillOpacity={1}
                fillRule={"nonzero"}
                stroke='none'
                id='path78'
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};
