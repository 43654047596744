import { makeStyles } from "@toolkit/ui";

export const useHealthProgramBenefitsGroupsStyle = makeStyles()(theme => ({
  label: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
  },
  group: {
    margin: "10px 0px",
    height: 50,
    padding: 5,
    display: "flex",
    paddingInline: 20,
    justifyContent: "flex-start",
    background: theme.palette.gray.light,
    borderRadius: 5,
    textTransform: "capitalize",
    "& svg": {
      width: 25,
      height: 25,
    },
    "& .MuiButton-endIcon": {
      display: "flex",
      justifyContent: "end",
      flex: 1,
    },
    "& path": {
      fill: `${theme.palette.primary.main} !important`,
    },
  },
  selectedGroup: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    "& path": {
      fill: `${theme.palette.common.white} !important`,
    },
    "&:hover": {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
  },
}));
