import React from "react";
import { Box, BoxProps, Typography, TypographyProps } from "../../base/mui";

type ValueWithLabelProps = {
  label: string;
  value?: string | React.ReactNode;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
  containerProps?: BoxProps;
};

export const ValueWithLabel: React.FC<ValueWithLabelProps> = props => {
  const { value, label, valueProps, labelProps, containerProps } = props;
  return (
    <Box {...containerProps}>
      <Typography fontWeight='bold' fontSize='14px' {...labelProps}>
        {label}:
      </Typography>
      <Typography fontSize='14px' {...valueProps}>
        {value}
      </Typography>
    </Box>
  );
};
