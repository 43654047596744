import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useDepartmentsAutocompleteQuery } from "./gql";
export const createDepartmentAutocompleteOption = (department) => {
    return createAutocompleteOption({ id: department === null || department === void 0 ? void 0 : department.id, name: department === null || department === void 0 ? void 0 : department.name }, "id", "name");
};
export const getDepartmentsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "vendorDepartments",
        query: useDepartmentsAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
