import { Grid, Typography } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { HealthProgramParametersCallPointsForm } from "../HealthProgramParametersCallPoints/HealthProgramParametersCallPointsForm";
import { HealthProgramParametersCallRatingsForm } from "../HealthProgramParametersCallRatings/HealthProgramParametersCallRatingsForm";
import { HealthProgramParametersAvailabilityForm } from "../HealthProgramParametersAvailability/HealthProgramParametersAvailabilityForm";
import { HealthProgramParametersSpecializationsForm } from "../HealthProgramParametersSpecializations/HealthProgramParametersSpecializationsForm";
import { useHealthProgramParametersFormStyle } from "./useHealthProgramParametersFormStyle";

export const HealthProgramParametersForm = () => {
  const { t } = useTranslation("admin");

  const { classes } = useHealthProgramParametersFormStyle();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.label}>{t("Call Points")}</Typography>
        <HealthProgramParametersCallPointsForm />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.label}>{t("Call Quality Ratings")}</Typography>
        <HealthProgramParametersCallRatingsForm />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.label}>{t("Availability")}</Typography>
        <HealthProgramParametersAvailabilityForm />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.label}>{t("Specializations")}</Typography>
        <HealthProgramParametersSpecializationsForm />
      </Grid>
    </Grid>
  );
};
