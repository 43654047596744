import { z } from "zod";

export const healthProgramBenefitsFormSchema = z.object({
  group: z.object({ id: z.string(), name: z.string().nullish() }),
  options: z.array(
    z.object({
      option: z.object({ id: z.string(), name: z.string().nullish() }),
      name: z.string().nullish(),
      value: z.coerce.number().nullish(),
      isAdded: z.boolean(),
    })
  ),
});
