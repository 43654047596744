/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VisitListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  filter?: Types.InputMaybe<Types.VisitFilterInput>;
  sortBy?: Types.InputMaybe<Types.VisitSortingInput>;
}>;


export type VisitListQuery = { __typename?: 'Query', visits?: { __typename?: 'VisitCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'VisitCountableEdge', node: { __typename?: 'Visit', id: string, type?: Types.VisitType | null, status?: Types.VisitStatus | null, paymentStatus?: Types.PaymentStatus | null, cancellationReason?: string | null, created?: any | null, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } } | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null, patient?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, contactNumber?: string | null, email?: string | null, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, insuranceId?: string | null, membershipStart?: any | null, membershipEnd?: any | null, payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } | null> | null } | null } }> } | null };


export const VisitListDocument = gql`
    query VisitList($first: Int, $after: String, $before: String, $last: Int, $filter: VisitFilterInput, $sortBy: VisitSortingInput) {
  visits(
    first: $first
    filter: $filter
    after: $after
    before: $before
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        type
        status
        paymentStatus
        cancellationReason
        created
        branch {
          id
          name
          nameAr
          vendor {
            id
            name
            nameAr
          }
        }
        doctor {
          id
          user {
            id
            fullName
          }
        }
        patient {
          id
          firstName
          lastName
          contactNumber
          email
          activeHealthProgramMembers {
            id
            insuranceId
            membershipStart
            membershipEnd
            payer {
              id
              name
              nameAr
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useVisitListQuery__
 *
 * To run a query within a React component, call `useVisitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useVisitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVisitListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useVisitListQuery(baseOptions?: Apollo.QueryHookOptions<VisitListQuery, VisitListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VisitListQuery, VisitListQueryVariables>(VisitListDocument, options);
      }
export function useVisitListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VisitListQuery, VisitListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VisitListQuery, VisitListQueryVariables>(VisitListDocument, options);
        }
export type VisitListQueryHookResult = ReturnType<typeof useVisitListQuery>;
export type VisitListLazyQueryHookResult = ReturnType<typeof useVisitListLazyQuery>;
export type VisitListQueryResult = Apollo.QueryResult<VisitListQuery, VisitListQueryVariables>;