import { appointmentsRoutes } from "@/pages/Appointments/constants";
import { optimaRoutes } from "@/pages/Optima/constants";
import { PlaygroundsRoutes } from "@/pages/Playgrounds/route";
import { programsRoutes } from "@/pages/Programs/constants";
import { visitsRoutes } from "@/pages/Visits/constants";
import { activityTrackerRoutes } from "pages/ActivityTracker/route";
import { adminManagedListsRoutes } from "pages/AdminManagedLists/route";
import { callbackRequestsRoutes } from "pages/CallbackRequests/route";
import { citiesRoutes } from "pages/Cities/constants";
import { dashboardRoutes } from "pages/Dashboard/route";
import { decisionsRoutes } from "pages/Decisions/constants";
import { healthInsuranceNetworkRoutes, healthInsurancesManagementRoutes } from "pages/HealthInsurance/route";
import { insuranceApprovalsRoutes } from "pages/InsuranceApprovals/route";
import { marketplaceRoutes } from "pages/Marketplace/constants/marketplace-routes.constants";
import { payersRoutes } from "pages/Payers/constants";
import { permissionsManagementRoutes } from "pages/PermissionsManagement/route";
import { prescriptionsOrdersTrackingRoutes } from "pages/PrescriptionsOrdersTracking";
import { providersManagementRoutes } from "pages/ProvidersManagement/route";
import { settingsRoutes } from "pages/Settings/route";
import { streamingRoutes } from "pages/Streaming/route";
import { surveysRoutes } from "pages/Surveys/route";
import { reviewPageUploadRoutes } from "pages/UploadPages/route";
import { ordersRoutes } from "./orders";
import { ordersAdminRoutes } from "./ordersMangment";
import { productsManagementRoutes } from "./products";
import { specializationManagementRoutes } from "./specialization";

export const ROUTE_PATHS = {
  PlaygroundsRoutes,
  dashboardRoutes,
  adminManagedListsRoutes,
  permissionsManagementRoutes,
  healthInsurancesManagementRoutes,
  healthInsuranceNetworkRoutes,
  programsRoutes,
  reviewPageUploadRoutes,
  settingsRoutes,
  streamingRoutes,
  productsManagement: productsManagementRoutes,
  providersManagementRoutes,
  citiesRoutes,
  decisionsRoutes,
  optimaRoutes,
  appointmentsRoutes,
  visitsRoutes,
  surveysRoutes,
  callbackRequestsRoutes,
  insuranceApprovalsRoutes,
  specializationManagementRoutes,
  ordersRoutes,
  ordersAdminRoutes,
  marketplaceRoutes,
  payersRoutes,
  prescriptionsOrdersTrackingRoutes,
  activityTrackerRoutes,
};
