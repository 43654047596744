import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CircularProgress, FormAutocomplete, FormTextField, Grid } from "@toolkit/ui";
import { FormProvider } from "react-hook-form";
import { destinationTypeOptions } from "@health/enum-options";
export const CredentialForm = props => {
    const { form, isLoading, onSubmit } = props;
    const { handleSubmit } = form;
    const { t } = useTranslation("domains");
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsxs("form", { onSubmit: handleSubmit(onSubmit), children: [_jsx(Grid, { container: true, spacing: formGirdSpacing, children: _jsx(Grid, { item: true, xs: 4, children: _jsx(FormAutocomplete, { name: 'destination', options: destinationTypeOptions, label: t("Destination") }) }) }), !isLoading ? (_jsxs(Grid, { container: true, spacing: formGirdSpacing, mt: 1, children: [_jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { name: 'license', label: t("License") }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { name: 'username', label: t("User Name") }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(FormTextField, { type: 'password', name: 'password', label: t("New Password") }) })] })) : (_jsx(CircularProgress, { sx: { mt: 2 } }))] }) })));
};
