import { zodSchema } from "@health/autocompletes";
import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";
import { createZodAutocomplete } from "@toolkit/core";
import { CareForType, CopayType } from "../../enum-options";
import { validateBenefitFields, validateProfitShares } from "../../others";
import {
  healthProgramParametersUpdateFormDefaultValues,
  healthProgramParametersUpdateFormSchema,
} from "../HealthProgramParametersUpdate/HealthProgramParametersUpdateFormSchema";
import { healthProgramBenefitsFormSchema } from "../HealthProgramBenefits/HealthProgramBenefitsFormSchema";
import { i18n } from "@toolkit/i18n";
import { isNil } from "lodash";

export const healthProgramUpsertFormSchema = healthProgramParametersUpdateFormSchema
  .extend({
    name: z.string().min(3).max(255),
    code: z.string().min(3).max(255),
    targetGroup: z.string().min(3).max(255),
    price: z.coerce.number().min(0),
    subscriptionPeriod: zodEnumSchema.healthProgramSubscriptionPeriod.nullish(),
    platformProfitShare: z.coerce.number().min(0).max(100).nullish(),
    vendorProfitShare: z.coerce.number().min(0).max(100).nullish(),
    operatorProfitShare: z.coerce.number().min(0).max(100).nullish(),
    copayType: createZodAutocomplete(CopayType).nullish(),
    copayValue: z.coerce.number().min(0).nullish(),
    description: z.string().min(1),
    payPerCall: z.boolean(),
    isPrescriptionEnabled: z.boolean(),
    isReferralsToSpecialistsEnabled: z.boolean(),
    isActive: z.boolean(),
    type: zodEnumSchema.healthProgramType,
    payer: zodSchema.payer.nullish(),
    networks: z.array(zodSchema.healthProgramNetwork),
    specializations: z.array(zodSchema.systemCode),
    careForTypes: z.array(createZodAutocomplete(CareForType)).min(1),
    membersLists: z.array(zodSchema.healthProgramMemberList),
    benefits: z.array(healthProgramBenefitsFormSchema),
    images: z.array(z.string()),
  })
  .superRefine((data, ctx) => {
    if (!data.payPerCall && !data.subscriptionPeriod) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["subscriptionPeriod"],
        message: i18n.t("Required"),
      });
    }

    if (data.copayType?.value && isNil(data.copayValue)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["copayValue"],
        message: i18n.t("Required"),
      });
    }

    validateProfitShares(data, ctx);
    validateBenefitFields(data, ctx);
  });

export type IHealthProgramUpsertFormValues = z.infer<typeof healthProgramUpsertFormSchema>;

export const healthProgramUpsertFormDefaultValues: Partial<IHealthProgramUpsertFormValues> = {
  name: undefined,
  code: undefined,
  targetGroup: undefined,
  price: undefined,
  subscriptionPeriod: undefined,
  platformProfitShare: undefined,
  vendorProfitShare: undefined,
  operatorProfitShare: undefined,
  copayType: undefined,
  copayValue: undefined,
  payPerCall: false,
  isPrescriptionEnabled: false,
  isReferralsToSpecialistsEnabled: false,
  isActive: false,
  description: undefined,
  type: undefined,
  payer: undefined,
  networks: [],
  specializations: [],
  careForTypes: [],
  membersLists: [],
  benefits: [],
  images: [],
  ...healthProgramParametersUpdateFormDefaultValues,
};
