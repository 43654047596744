import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useVendorDepartmentsAutocompleteQuery } from "./gql";
export const createVendorDepartmentAutocompleteOption = (department) => {
    return createAutocompleteOption({ id: department === null || department === void 0 ? void 0 : department.id, name: department === null || department === void 0 ? void 0 : department.name }, "id", "name");
};
export const getVendorDepartmentsAutocompleteFilter = ({ name, multiple }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "vendorDepartments",
        query: useVendorDepartmentsAutocompleteQuery,
        labelBy: "name",
        backendAccessor: "id",
    });
};
