import React, { FC, Fragment } from "react";
import { Box, FormCheckbox, FormNumberField, FormTextField, Grid, Typography } from "@toolkit/ui";
import { IHealthProgramBenefit } from "@/pages/HealthPrograms/types";
import { useTranslation } from "@toolkit/i18n";
import { useHealthProgramBenefitsOptionsFormStyle } from "./useHealthProgramBenefitsOptionsFormStyle";

type HealthProgramBenefitsOptionsFormProps = {
  benefits: IHealthProgramBenefit[];
  selectedGroupIndex: number;
};

export const HealthProgramBenefitsOptionsForm: FC<HealthProgramBenefitsOptionsFormProps> = props => {
  const { benefits, selectedGroupIndex } = props;

  const { t } = useTranslation("admin");

  const { classes } = useHealthProgramBenefitsOptionsFormStyle();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Typography className={classes.label}>{t("Option")}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography className={classes.label}>{t("Name")}</Typography>
          </Grid>

          <Grid item xs={3}>
            <Typography className={classes.label}>{t("Value")}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          {benefits?.[selectedGroupIndex]?.options?.map((item, optionIndex) => {
            return (
              <Fragment key={`${selectedGroupIndex}-${item?.option?.id}`}>
                <Grid item xs={5}>
                  <Box className={classes.checkboxWrapper}>
                    <FormCheckbox name={`benefits.${selectedGroupIndex}.options.${optionIndex}.isAdded`} label={item?.option?.name!} />
                  </Box>
                </Grid>

                <Grid item xs={3}>
                  <FormTextField
                    name={`benefits.${selectedGroupIndex}.options.${optionIndex}.name`}
                    label={t("Name")}
                    placeholder={t("Name")}
                    disabled={!item?.isAdded}
                  />
                </Grid>

                <Grid item xs={3}>
                  <FormNumberField
                    name={`benefits.${selectedGroupIndex}.options.${optionIndex}.value`}
                    label={t("Value")}
                    placeholder={t("Value")}
                    disabled={!item?.isAdded}
                  />
                </Grid>
              </Fragment>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
