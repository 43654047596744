import React, { FC } from "react";
import { MuiDeleteIcon, MuiZoomInIcon } from "../../base/mui-icons";
import { styles } from "./styles";
import { ImageDisplayProps } from "./types";
import ZoomUploadedImage from "./ZoomUploadedImage";

export const ImageDisplay: FC<ImageDisplayProps> = ({
  uploadedImage,
  canDeleteImage,
  onDelete: handleDelete,
  icon,
  classes: classesProp = { containerImg: "", img: "", icons: "" },
}) => {
  const { classes, cx } = styles();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={cx(classes.containerImg, classesProp.containerImg)}>
        <img className={cx(classes.img, classesProp.img)} src={uploadedImage} alt='ImageUpload' />
        <div className={cx(classes.icons, classes.icons)} onClick={handleClickOpen}>
          {canDeleteImage && <MuiDeleteIcon width={icon?.width ?? 30} height={icon?.height ?? 30} color='error' onClick={handleDelete} />}
          <MuiZoomInIcon width={icon?.width ?? 70} height={icon?.height ?? 70} />
        </div>
      </div>
      {open && <ZoomUploadedImage open={open} onClose={handleClose} uploadedImage={uploadedImage} />}
    </>
  );
};
