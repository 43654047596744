import { FormAutocomplete, FormNumberField, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import { healthProgramSubscriptionPeriodOptions } from "@health/enum-options";
import { CopayType, copayTypeOptions } from "@/pages/HealthPrograms/enum-options";
import { useCustomFormContext } from "@toolkit/core";
import { IHealthProgramUpsertFormValues } from "../HealthProgramUpsert/HealthProgramUpsertFormSchema";
import { useSiteSettingsQuery } from "@/pages/Settings/gql";
import { FC } from "react";

type HealthProgramInformationFormProps = {
  isUpdateMode: boolean;
};

export const HealthProgramInformationForm: FC<HealthProgramInformationFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("admin");

  const { data } = useSiteSettingsQuery();
  const currency = data?.siteSettings?.defaultCurrency;

  const form = useCustomFormContext<IHealthProgramUpsertFormValues>();
  const { watch } = form;

  const payPerCall = watch("payPerCall");
  const copay = watch("copayType")?.value;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"code"} label={t("Code")} placeholder={t("Code")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormTextField name={"targetGroup"} label={t("Targeted Group")} placeholder={t("Targeted Group")} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormNumberField
          name={"price"}
          label={t("Monthly Price")}
          placeholder={t("Monthly Price")}
          isArrowsHidden
          InputProps={{ endAdornment: currency }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"copayType"} label={t("Copay")} options={copayTypeOptions} />
      </Grid>

      {!!copay && (
        <Grid item xs={12} sm={6} md={4}>
          <FormNumberField
            name={"copayValue"}
            label={copay === CopayType.Fixed ? t("Copay Amount") : t("Copay Percentage")}
            placeholder={copay === CopayType.Fixed ? t("Copay Amount") : t("Copay Percentage")}
            isFloatAllowed
            isArrowsHidden
            InputProps={{ endAdornment: copay === CopayType.Fixed ? currency : "%" }}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <FormNumberField
              name={"platformProfitShare"}
              label={t("Platform Profit Share Percentage")}
              placeholder={t("Platform Profit Share Percentage")}
              isFloatAllowed
              isArrowsHidden
              InputProps={{ endAdornment: "%" }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormNumberField
              name={"vendorProfitShare"}
              label={t("Vendor Profit Share Percentage")}
              placeholder={t("Vendor Profit Share Percentage")}
              isFloatAllowed
              isArrowsHidden
              InputProps={{ endAdornment: "%" }}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <FormNumberField
              name={"operatorProfitShare"}
              label={t("Operator Profit Share Percentage")}
              placeholder={t("Operator Profit Share Percentage")}
              isFloatAllowed
              isArrowsHidden
              InputProps={{ endAdornment: "%" }}
            />
          </Grid>
        </Grid>
      </Grid>

      {!payPerCall && (
        <Grid item xs={12} sm={6} md={4}>
          <FormAutocomplete name={"subscriptionPeriod"} label={t("Subscription Period")} options={healthProgramSubscriptionPeriodOptions} />
        </Grid>
      )}

      <Grid item xs={12}>
        <FormTextField name={"description"} label={t("Description")} placeholder={t("Description")} multiline rows={4} />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormSwitch name={"isPrescriptionEnabled"} label={t("Prescription")} />
          </Grid>

          <Grid item xs={2}>
            <FormSwitch name={"payPerCall"} label={t("Pay Per Call")} disabled={isUpdateMode} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <FormSwitch name={"isReferralsToSpecialistsEnabled"} label={t("Referrals To Specialists")} />
          </Grid>

          <Grid item xs={2}>
            <FormSwitch name={"isActive"} label={t("Is Active")} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
