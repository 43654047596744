import { makeStyles } from "@toolkit/ui";

export const useHealthProgramBenefitsOptionsFormStyle = makeStyles()(theme => ({
  label: {
    fontSize: theme.mixins.fonts.fontSize.md,
    fontWeight: theme.mixins.fonts.fontWeight.semiBold,
  },
  checkboxWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
}));
