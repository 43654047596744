/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { Children, cloneElement, Fragment, isValidElement, useEffect, useState } from "react";
import { Box, Button, IconButton, Step, StepLabel, Stepper, Typography } from "../../base/mui";
import { CustomIcon } from "../CustomIcon";
import { useTimelineStyles } from "./useTimelineStyles";
import { useTranslation } from "@toolkit/i18n";

interface AnnualTimeLineProps<T> {
  year?: number;
  timeline: {
    label: string;
    activities: T[];
    hasIntervention?: boolean;
    interventions?: string[];
    isMinMonth?: boolean;
    isMaxMonth?: boolean;
  }[];
  children: React.ReactNode;
  isDisableNext?: boolean;
  isDisablePrev?: boolean;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

export const AnnualTimeLine = <T,>({
  year,
  timeline,
  children,
  isDisableNext,
  isDisablePrev,
  onIncrement,
  onDecrement,
}: AnnualTimeLineProps<T>) => {
  const [step, setStep] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [interventions, setInterventions] = useState<string[]>([]);

  const { t } = useTranslation();

  const { classes, theme } = useTimelineStyles();

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    setWindowWidth(window.innerWidth);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);
  const numItemsToShow = window.innerWidth <= 940 ? Math.floor(windowWidth / 75) - 1 : 12;

  const handleNext = () => {
    step === timeline.length - numItemsToShow
      ? (setStep(0), onIncrement?.())
      : setStep(Math.min(step + numItemsToShow, timeline.length - numItemsToShow));
  };

  const handlePrev = () => {
    step === 0 ? (setStep(timeline.length - numItemsToShow), onDecrement?.()) : setStep(Math.max(step - numItemsToShow, 0));
  };

  const showArrows = timeline.length > numItemsToShow;

  const handleMouseEnter = (itemInterventions: string[]) => {
    setInterventions(itemInterventions);
  };

  const handleMouseLeave = () => {
    setInterventions([]);
  };

  return (
    <Box display='flex' alignItems='center' justifyContent='center' flexDirection={"column"} position='relative'>
      <Box display={"flex"} gap={"2px"} justifyContent={"center"} width={"100%"} minHeight={"34px"} alignItems={"flex-end"}>
        {interventions?.length > 0 && (
          <>
            <Typography fontSize={theme.mixins.fonts.fontSize.md} fontWeight={theme.mixins.fonts.fontWeight.medium}>
              {interventions?.length === 1 ? t("Intervention") : t("Interventions")}:
            </Typography>

            {interventions?.join(", ")}
          </>
        )}
      </Box>

      {year ? (
        <>
          {step !== 0 ? (
            <IconButton className={classes.btn} onClick={handlePrev}>
              <CustomIcon icon='arrow' color='white' transform='rotate(180deg)' viewBox='-3 -3 24 24' />
            </IconButton>
          ) : (
            <Button
              className={`${classes.year} ${classes.prevYear}`}
              onClick={handlePrev}
              startIcon={<CustomIcon icon='arrow' color={theme.palette.common.white} transform='rotate(180deg)' viewBox='-4 -3 24 24' />}
            >
              {year - 1}
            </Button>
          )}
        </>
      ) : (
        showArrows && (
          <IconButton disabled={step === 0 || isDisablePrev} className={classes.btn} onClick={handlePrev}>
            <CustomIcon icon='arrow' color='white' transform='rotate(180deg)' viewBox='-3 -3 24 24' />
          </IconButton>
        )
      )}

      <Stepper connector={<Box />}>
        {timeline?.map((item, i) => {
          const childrenWithProps = Children.map(children, child => {
            if (isValidElement(child)) {
              return cloneElement<any>(child, { activities: item?.activities });
            }

            return child;
          });

          return (
            <Step
              key={item?.label}
              className={classes.step}
              sx={{ display: i >= step && i < step + numItemsToShow ? "flex" : "none !important" }}
            >
              <StepLabel
                StepIconComponent={props => (
                  <Box
                    {...props}
                    className={classes.stepIcon}
                    sx={{
                      color: item?.isMinMonth
                        ? theme.palette.secondary.main
                        : timeline[i - 1]?.isMaxMonth
                        ? theme.palette.secondary.main
                        : theme.palette.stale.main,
                    }}
                    marginBottom='34px'
                  />
                )}
                classes={{
                  root: classes.stepLabel,
                  iconContainer: classes.iconContainer,
                }}
              >
                <Box className={classes.centerFlex}>
                  <Box
                    className={window.innerWidth <= 1300 ? classes.activitiesMonth : classes.activities}
                    onMouseEnter={() => handleMouseEnter(item?.interventions || [])}
                    onMouseLeave={handleMouseLeave}
                    sx={{
                      backgroundColor: item?.hasIntervention ? "#F1EFF7" : "transparent",
                      ...(item?.isMinMonth
                        ? { borderLeft: "2px solid" }
                        : i === timeline.length - 1 && item?.isMaxMonth
                        ? { borderRight: "2px solid" }
                        : timeline[i - 1]?.isMaxMonth
                        ? { borderLeft: "2px solid" }
                        : { border: "none" }),
                    }}
                  >
                    {childrenWithProps}
                  </Box>

                  <Box
                    display='flex'
                    alignItems='baseline'
                    width={i == timeline?.length - 1 ? (window.innerWidth > 1300 ? 72 : 54) : undefined}
                  >
                    {Array.from({ length: 4 }, (_, index) => (
                      <Fragment key={index}>
                        <Box className={classes.separate} mx={window.innerWidth > 1300 ? 1 : "6px"} />

                        {i == timeline?.length - 1 && index === 3 && (
                          <Box
                            className={classes.stepIcon}
                            sx={{
                              marginBottom: "0px !important",
                              marginLeft: window.innerWidth > 1300 ? "12px" : "4px",
                              marginTop: "-20px",
                              borderColor: item?.hasIntervention
                                ? `${theme.palette.secondary.main} !important`
                                : `${theme.palette.stale.main} !important`,
                            }}
                          />
                        )}
                      </Fragment>
                    ))}
                  </Box>

                  <Typography
                    fontSize={theme.mixins.fonts.fontSize.xs}
                    marginTop={2}
                    color={theme.palette.primary.main}
                    fontWeight={theme.mixins.fonts.fontWeight.medium}
                  >
                    {item?.label}
                  </Typography>
                </Box>
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {year ? (
        <>
          {step !== timeline.length - numItemsToShow ? (
            <IconButton disabled={step === timeline.length - numItemsToShow || isDisableNext} className={classes.btn} onClick={handleNext}>
              <CustomIcon icon='arrow' color='white' viewBox='-3 -3 24 24' />
            </IconButton>
          ) : (
            <Button className={classes.year} onClick={handleNext} endIcon={<CustomIcon icon='arrow' color='white' viewBox='-4 -3 24 24' />}>
              {year + 1}
            </Button>
          )}
        </>
      ) : (
        showArrows && (
          <IconButton disabled={step === timeline.length - numItemsToShow || isDisableNext} className={classes.btn} onClick={handleNext}>
            <CustomIcon icon='arrow' color='white' viewBox='-3 -3 24 24' />
          </IconButton>
        )
      )}
    </Box>
  );
};

AnnualTimeLine.defaultProps = {
  year: undefined,
  timeline: [],
  children: null,
  isDisableNext: false,
  isDisablePrev: false,
  onIncrement: () => {},
  onDecrement: () => {},
};
