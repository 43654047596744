import { ArrowButtonRight, ArrowLeftSliderIcon, Box, Button, CheckmarkIcon, CircleStrokedIcon, IconButton, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import React, { FC } from "react";
import { IHealthProgramBenefit } from "../../types";
import { useHealthProgramBenefitsGroupsStyle } from "./useHealthProgramBenefitsGroupsStyle";

export type IHealthProgramBenefitsGroupsEvent = {
  type: "CLICK" | "CHECK_ALL" | "UNCHECK_ALL";
  payload: {
    index: number;
  };
};

type HealthProgramBenefitsGroupsProps = {
  benefits: IHealthProgramBenefit[];
  selectedGroupIndex?: number;
  onChange: (event: IHealthProgramBenefitsGroupsEvent) => void;
};

export const HealthProgramBenefitsGroups: FC<HealthProgramBenefitsGroupsProps> = props => {
  const { benefits, selectedGroupIndex, onChange } = props;

  const { t } = useTranslation("admin");

  const { classes, cx } = useHealthProgramBenefitsGroupsStyle();

  const onGroupClick = (index: number) => {
    onChange({
      type: "CLICK",
      payload: { index },
    });
  };

  const onCheckAllClick = (index: number) => {
    onChange({
      type: "CHECK_ALL",
      payload: { index },
    });
  };

  const onUnCheckAllClick = (index: number) => {
    onChange({
      type: "UNCHECK_ALL",
      payload: { index },
    });
  };

  return (
    <Box>
      <Typography className={classes.label} marginBottom={2}>
        {t("Group")}
      </Typography>

      {benefits?.map((item, index) => (
        <Box key={item?.group?.id} marginBottom={2}>
          <Button
            fullWidth
            onClick={() => onGroupClick(index)}
            variant={"text"}
            className={cx(classes.group, index === selectedGroupIndex && classes.selectedGroup)}
            startIcon={
              item?.options?.some(option => option?.isAdded) ? (
                <IconButton onClick={() => onUnCheckAllClick(index)}>
                  <CheckmarkIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => onCheckAllClick(index)}>
                  <CircleStrokedIcon />
                </IconButton>
              )
            }
            endIcon={index === selectedGroupIndex ? pickLocalizedValue(<ArrowButtonRight />, <ArrowLeftSliderIcon />) : undefined}
          >
            {item?.group?.name}
          </Button>
        </Box>
      ))}
    </Box>
  );
};
