import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { healthProgramMembersPaths } from "../constants";

export const useSetHealthProgramMembersBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Members");

  const healthProgramMemberListBreadcrumb = useMemo(() => {
    return {
      name: t("Members"),
      route: healthProgramMembersPaths.list.fullPath,
    };
  }, [t]);

  const setHealthProgramMemberListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setHealthProgramMemberCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [healthProgramMemberListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, healthProgramMemberListBreadcrumb, t]);

  const setHealthProgramMemberUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthProgramMemberListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, healthProgramMemberListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setHealthProgramMemberListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setHealthProgramMemberCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setHealthProgramMemberUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setHealthProgramMemberListContainerBreadcrumb,
    setHealthProgramMemberCreateContainerBreadcrumb,
    setHealthProgramMemberUpdateContainerBreadcrumb,
  ]);
};
