import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { callbackRequestsTitleBreadcrumb } from "pages/CallbackRequests/constants/callback-requests-breadcrumbs";
import { FC, useEffect } from "react";
import { GridProvider } from "shared/components";
import { useCallbackRequestsQuery } from "../gql";
import { useCallbackRequestListColumns } from "./Columns/useCallbackRequestListColumns";

const CallbackRequestList: FC = () => {
  // const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const requestColumns = useCallbackRequestListColumns();
  useEffect(() => {
    setBreadCrumb({
      title: callbackRequestsTitleBreadcrumb(),
    });
  }, []);

  return (
    <GridProvider hasTableSetting gridName='CallbackRequest' columns={requestColumns} query={useCallbackRequestsQuery}>
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
export default CallbackRequestList;
