import { zodSchema } from "@health/autocompletes";
import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";

export const healthProgramMemberUpsertFormSchema = z.object({
  firstName: z.string().min(3).max(30),
  lastName: z.string().min(3).max(30),
  gender: zodEnumSchema.userGender,
  dateOfBirth: z.string(),
  nationalId: z.string().min(1),
  iOHealthId: z.string().min(1),
  patientIdType: zodEnumSchema.patientIdType,
  nationality: zodSchema.systemCode.nullish(),
  documentExpiryDate: z.string(),
  phoneNumber: z.string(),
  email: z.string().email(),
  memberLists: z.array(zodSchema.healthProgramMemberList),
  network: z.string().min(1),
  payer: zodSchema.payer,
  coverageByType: zodEnumSchema.coverageByType,
  memberShipStartDate: z.string(),
  memberShipEndDate: z.string(),
  insuranceId: z.string().min(1),
  insurancePolicyNumber: z.string().min(1),
});

export type IHealthProgramMemberUpsertFormValues = z.infer<typeof healthProgramMemberUpsertFormSchema>;

export const healthProgramMemberUpsertFormDefaultValues: Partial<IHealthProgramMemberUpsertFormValues> = {
  firstName: undefined,
  lastName: undefined,
  gender: undefined,
  dateOfBirth: undefined,
  nationalId: undefined,
  iOHealthId: undefined,
  patientIdType: undefined,
  nationality: undefined,
  documentExpiryDate: undefined,
  phoneNumber: undefined,
  email: undefined,
  memberLists: [],
  network: undefined,
  payer: undefined,
  coverageByType: undefined,
  memberShipStartDate: undefined,
  memberShipEndDate: undefined,
  insuranceId: undefined,
  insurancePolicyNumber: undefined,
};
