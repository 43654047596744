import { HealthProgramOptionType } from "@health/queries/types";
import { i18n } from "@toolkit/i18n";
export const healthProgramOptionTypeOptionsMap = {
    [HealthProgramOptionType.Include]: {
        key: HealthProgramOptionType.Include,
        get label() {
            return i18n.t("Include", { ns: "domains" });
        },
        value: HealthProgramOptionType.Include,
    },
    [HealthProgramOptionType.Limitation]: {
        key: HealthProgramOptionType.Limitation,
        get label() {
            return i18n.t("Limitation", { ns: "domains" });
        },
        value: HealthProgramOptionType.Limitation,
    },
};
export const healthProgramOptionTypeOptions = Object.values(healthProgramOptionTypeOptionsMap);
