import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const SetPharmacyCredentialsDocument = gql `
    mutation SetPharmacyCredentials($destinationType: DestinationType!, $pharmacyCredentials: PharmacyCredentialsInput!) {
  setPharmacyCredentials(
    destinationType: $destinationType
    pharmacyCredentials: $pharmacyCredentials
  ) {
    password
    token
    userName
  }
}
    `;
/**
 * __useSetPharmacyCredentialsMutation__
 *
 * To run a mutation, you first call `useSetPharmacyCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPharmacyCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPharmacyCredentialsMutation, { data, loading, error }] = useSetPharmacyCredentialsMutation({
 *   variables: {
 *      destinationType: // value for 'destinationType'
 *      pharmacyCredentials: // value for 'pharmacyCredentials'
 *   },
 * });
 */
export function useSetPharmacyCredentialsMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(SetPharmacyCredentialsDocument, options);
}
