/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.HealthProgramNetworkUpdateInput;
}>;


export type HealthProgramNetworkUpdateMutation = { __typename?: 'Mutation', healthProgramNetworkUpdate?: { __typename?: 'HealthProgramNetworkCRUD', entity?: { __typename?: 'HealthProgramNetwork', id: string } | null } | null };


export const HealthProgramNetworkUpdateDocument = gql`
    mutation HealthProgramNetworkUpdate($id: ID!, $input: HealthProgramNetworkUpdateInput!) {
  healthProgramNetworkUpdate(id: $id, input: $input) {
    entity {
      id
    }
  }
}
    `;
export type HealthProgramNetworkUpdateMutationFn = Apollo.MutationFunction<HealthProgramNetworkUpdateMutation, HealthProgramNetworkUpdateMutationVariables>;

/**
 * __useHealthProgramNetworkUpdateMutation__
 *
 * To run a mutation, you first call `useHealthProgramNetworkUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramNetworkUpdateMutation, { data, loading, error }] = useHealthProgramNetworkUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramNetworkUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramNetworkUpdateMutation, HealthProgramNetworkUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramNetworkUpdateMutation, HealthProgramNetworkUpdateMutationVariables>(HealthProgramNetworkUpdateDocument, options);
      }
export type HealthProgramNetworkUpdateMutationHookResult = ReturnType<typeof useHealthProgramNetworkUpdateMutation>;
export type HealthProgramNetworkUpdateMutationResult = Apollo.MutationResult<HealthProgramNetworkUpdateMutation>;
export type HealthProgramNetworkUpdateMutationOptions = Apollo.BaseMutationOptions<HealthProgramNetworkUpdateMutation, HealthProgramNetworkUpdateMutationVariables>;