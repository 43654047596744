/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.HealthProgramMemberInput;
}>;


export type HealthProgramMemberUpdateMutation = { __typename?: 'Mutation', healthProgramMemberUpdate?: { __typename?: 'HealthProgramMemberCRUD', entity?: { __typename?: 'HealthProgramMember', id: string } | null } | null };


export const HealthProgramMemberUpdateDocument = gql`
    mutation HealthProgramMemberUpdate($id: ID!, $input: HealthProgramMemberInput!) {
  healthProgramMemberUpdate(input: $input, id: $id) {
    entity {
      id
    }
  }
}
    `;
export type HealthProgramMemberUpdateMutationFn = Apollo.MutationFunction<HealthProgramMemberUpdateMutation, HealthProgramMemberUpdateMutationVariables>;

/**
 * __useHealthProgramMemberUpdateMutation__
 *
 * To run a mutation, you first call `useHealthProgramMemberUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramMemberUpdateMutation, { data, loading, error }] = useHealthProgramMemberUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramMemberUpdateMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramMemberUpdateMutation, HealthProgramMemberUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramMemberUpdateMutation, HealthProgramMemberUpdateMutationVariables>(HealthProgramMemberUpdateDocument, options);
      }
export type HealthProgramMemberUpdateMutationHookResult = ReturnType<typeof useHealthProgramMemberUpdateMutation>;
export type HealthProgramMemberUpdateMutationResult = Apollo.MutationResult<HealthProgramMemberUpdateMutation>;
export type HealthProgramMemberUpdateMutationOptions = Apollo.BaseMutationOptions<HealthProgramMemberUpdateMutation, HealthProgramMemberUpdateMutationVariables>;