import { FormNumberField, Grid, HintComponent } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const HealthProgramParametersAvailabilityForm = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"availabilityHours"}
          label={t("Availability Hours")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: <HintComponent title={t("Number of availability hours expected to be met by each enrolled doctor per month")} />,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"unattendedCallPenalty"}
          label={t("Call Penalty")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            // readOnly,
            endAdornment: <HintComponent title={t("Penalty factor to be multiplied by the total number of unattended calls per month")} />,
          }}
        />
      </Grid>
    </Grid>
  );
};
