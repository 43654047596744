/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type HealthProgramActivationMutation = { __typename?: 'Mutation', healthProgramUpdateActiveStatus?: { __typename?: 'HealthProgramCRUD', healthProgram?: { __typename?: 'HealthProgram', id: string, isActive?: boolean | null } | null } | null };


export const HealthProgramActivationDocument = gql`
    mutation HealthProgramActivation($id: ID!) {
  healthProgramUpdateActiveStatus(id: $id) {
    healthProgram {
      id
      isActive
    }
  }
}
    `;
export type HealthProgramActivationMutationFn = Apollo.MutationFunction<HealthProgramActivationMutation, HealthProgramActivationMutationVariables>;

/**
 * __useHealthProgramActivationMutation__
 *
 * To run a mutation, you first call `useHealthProgramActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramActivationMutation, { data, loading, error }] = useHealthProgramActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthProgramActivationMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramActivationMutation, HealthProgramActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramActivationMutation, HealthProgramActivationMutationVariables>(HealthProgramActivationDocument, options);
      }
export type HealthProgramActivationMutationHookResult = ReturnType<typeof useHealthProgramActivationMutation>;
export type HealthProgramActivationMutationResult = Apollo.MutationResult<HealthProgramActivationMutation>;
export type HealthProgramActivationMutationOptions = Apollo.BaseMutationOptions<HealthProgramActivationMutation, HealthProgramActivationMutationVariables>;