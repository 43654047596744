import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import React from "react";
import { GridProvider } from "shared/components";
import { useVisitListQuery } from "../../gql";
import { useSetVisitsBreadcrumbs } from "../../hooks";
import { useVisitListContainerColumns } from "./useVisitListContainerColumns";

export const VisitListContainer = () => {
  useSetVisitsBreadcrumbs("LIST");

  return (
    <GridProvider gridName={"visitList"} query={useVisitListQuery} columns={useVisitListContainerColumns()} hasTableSetting>
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
