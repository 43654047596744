/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkProviderActivationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type HealthProgramNetworkProviderActivationMutation = { __typename?: 'Mutation', healthProgramNetworkProviderUpdateActiveStatus?: { __typename?: 'HealthProgramNetworkProviderCRUD', entity?: { __typename?: 'HealthProgramNetworkProvider', id: string, isActive?: boolean | null } | null } | null };


export const HealthProgramNetworkProviderActivationDocument = gql`
    mutation HealthProgramNetworkProviderActivation($id: ID!) {
  healthProgramNetworkProviderUpdateActiveStatus(id: $id) {
    entity {
      id
      isActive
    }
  }
}
    `;
export type HealthProgramNetworkProviderActivationMutationFn = Apollo.MutationFunction<HealthProgramNetworkProviderActivationMutation, HealthProgramNetworkProviderActivationMutationVariables>;

/**
 * __useHealthProgramNetworkProviderActivationMutation__
 *
 * To run a mutation, you first call `useHealthProgramNetworkProviderActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkProviderActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramNetworkProviderActivationMutation, { data, loading, error }] = useHealthProgramNetworkProviderActivationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthProgramNetworkProviderActivationMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramNetworkProviderActivationMutation, HealthProgramNetworkProviderActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramNetworkProviderActivationMutation, HealthProgramNetworkProviderActivationMutationVariables>(HealthProgramNetworkProviderActivationDocument, options);
      }
export type HealthProgramNetworkProviderActivationMutationHookResult = ReturnType<typeof useHealthProgramNetworkProviderActivationMutation>;
export type HealthProgramNetworkProviderActivationMutationResult = Apollo.MutationResult<HealthProgramNetworkProviderActivationMutation>;
export type HealthProgramNetworkProviderActivationMutationOptions = Apollo.BaseMutationOptions<HealthProgramNetworkProviderActivationMutation, HealthProgramNetworkProviderActivationMutationVariables>;