import { useTranslation } from "@toolkit/i18n";
import { useNavigate, useParams } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useHealthProgramNetworkProviderCreateMutation } from "../../gql";
import { healthProgramNetworksPaths } from "../../constants";
import { convertHealthProgramNetworkProviderFormValuesToBackEndValues } from "../../others";
import { useSetHealthProgramNetworksProvidersBreadcrumbs } from "../../hooks";
import { IHealthProgramNetworkProviderCreateContainerParams } from "../../types";
import {
  HealthProgramNetworkProviderUpsertForm,
  IHealthProgramNetworkProviderUpsertFormEvent,
} from "../../forms/HealthProgramNetworkProviderUpsert/HealthProgramNetworkProviderUpsertForm";

export const HealthProgramNetworkProviderCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { networkId } = useParams<IHealthProgramNetworkProviderCreateContainerParams>();

  const [createHealthProgramNetworkProvider, { loading: isSubmitting }] = useHealthProgramNetworkProviderCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramNetworkProviderCreate?.entity?.id) {
        succeeded(t("Network Provider created successfully"));
        navigate(healthProgramNetworksPaths.providerList.fullPathWithParams({ networkId: networkId! }));
      } else {
        failed(t("Network Provider creation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramNetworkProviderUpsertFormChange = (event: IHealthProgramNetworkProviderUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramNetworkProviderFormValuesToBackEndValues(event.payload.values);

      createHealthProgramNetworkProvider({
        variables: {
          input: { ...values, healthProgramNetwork: networkId },
        },
      });
    }
  };

  useSetHealthProgramNetworksProvidersBreadcrumbs("CREATE", { networkId: networkId! });

  return (
    <HealthProgramNetworkProviderUpsertForm
      buttonLabel={t("Create")}
      networkId={networkId!}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramNetworkProviderUpsertFormChange}
    />
  );
};
