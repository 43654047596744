import { useCallback, useEffect } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";

export const useSetAppointmentsBreadcrumbs = (containerType: "LIST") => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Appointments");

  const setAppointmentListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setAppointmentListContainerBreadcrumb();
    }
  }, [containerType, setAppointmentListContainerBreadcrumb]);
};
