import { getTokenPayloadData } from "./utils";

export const getUserPermissions = () => {
  const userData = getTokenPayloadData();
  return userData?.permissions || [];
};

export const isSuperAdmin = () => {
  const userData = getTokenPayloadData();
  return userData?.app_role?.toLowerCase() === "ADMIN".toLowerCase() && userData?.app_type?.toLowerCase() === "ADMIN".toLowerCase();
};

export const hasPermission = (permission: string | { code: string }): boolean => {
  // ALLOW SUPER ADMIN USERS TO BYPASS PERMISSION CHECKS
  if (isSuperAdmin()) return true;

  const permissionCode: null | string = Object.prototype.hasOwnProperty.call(permission, "code")
    ? (permission as { code: string }).code
    : (permission as string) || null;

  if (!permissionCode) return false;
  return getUserPermissions()?.some((p: string) => p?.toLowerCase() === permissionCode?.toLowerCase());
};

export const hasAnyPermission = (permissions: Array<string | { code: string }>): boolean => {
  return permissions.some(hasPermission);
};

export const hasAllPermission = (permissions: Array<string | { code: string }>): boolean => {
  return permissions.every(hasPermission);
};
