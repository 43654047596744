import { FC, useEffect } from "react";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useGridContext } from "@toolkit/ui";
import { FileDownload, FileImportDialog } from "@health/domains";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "@toolkit/i18n";
import { hasPermission, useOpenState } from "@toolkit/core";
import { BulkTemplateCode, PermissionEnum } from "@health/queries/types";
import { healthProgramNetworksPaths } from "../../constants";

type HealthProgramNetworkProviderListPageWrapperProps = {
  networkId: string;
};

export const HealthProgramNetworkProviderListPageWrapper: FC<HealthProgramNetworkProviderListPageWrapperProps> = props => {
  const { networkId } = props;

  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { open, handleToggle } = useOpenState();
  const { customTableProps } = useGridContext();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const onAddNewItemClick = () => {
    navigate(healthProgramNetworksPaths.providerCreate.fullPathWithParams({ networkId }));
  };

  const handleSuccessUpload = () => {
    customTableProps?.onUpdateGrid && customTableProps?.onUpdateGrid();
  };

  useEffect(() => {
    !customTableProps?.isLoading && !customTableProps?.data?.length && handleToggle();
  }, []);

  return (
    <PageWrapper
      filters={<FilterGrid />}
      actions={
        <>
          <TableSettingComponent />

          {hasManageHealthProgramsPermission && (
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick}>
              <FileImportDialog
                title={t("Import Providers")}
                subTitle={t("Upload Providers list you want to get")}
                isOpen={open}
                onToggle={handleToggle}
                onSuccessUpload={handleSuccessUpload}
                templateCode={BulkTemplateCode.HealthProgramNetworkProvider}
              />

              <FileDownload templateCode={BulkTemplateCode.HealthProgramNetworkProvider} />
            </MainListActions>
          )}
        </>
      }
    >
      <TableGrid />
    </PageWrapper>
  );
};
