import { FormAutocomplete, FormPhoneInput, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { payerTpoOptions, payerTypeOptions } from "@health/enum-options";
import { PayersAutocomplete } from "@health/autocompletes";
import React, { FC } from "react";
import { useCustomFormContext } from "@toolkit/core";
import { IPayerUpsertFormValues } from "../PayerUpsert/PayerUpsertFormSchema";
import { useTranslation } from "@toolkit/i18n";
import { PayerType } from "@health/queries/types";

type PayerInformationFormProps = {
  isUpdateMode: boolean;
};

export const PayerInformationForm: FC<PayerInformationFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("admin");

  const form = useCustomFormContext<IPayerUpsertFormValues>();

  const type = form.watch("type")?.value;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={3}>
        <FormTextField name={"name"} label={t("Name")} />
      </Grid>

      <Grid item xs={12} sm={4} md={3}>
        <FormTextField name={"nameAr"} label={t("Arabic Name")} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormTextField name={"contactName"} label={t("Contact Name")} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormTextField name={"contactEmail"} label={t("Contact Email")} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormPhoneInput name={"contactMobileNumber"} label={t("Contact Mobile Number")} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormPhoneInput name={"contactPhoneNumber"} label={t("Contact Phone Number")} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormTextField name={"licenseNumber"} label={t("License Number")} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormAutocomplete name={"tpo"} label={t("TPO")} options={payerTpoOptions} />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <FormAutocomplete name={"type"} label={t("Type")} options={payerTypeOptions} disabled={isUpdateMode} />
      </Grid>

      {type === PayerType.Tpa && (
        <Grid item xs={12} sm={6} md={3}>
          <PayersAutocomplete name={"parent"} />
        </Grid>
      )}

      <Grid item xs={12}>
        <FormSwitch name={"isActive"} label={t("Is Active")} />
      </Grid>
    </Grid>
  );
};
