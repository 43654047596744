export var PatientEligibilityBenefitsEnum;
(function (PatientEligibilityBenefitsEnum) {
    PatientEligibilityBenefitsEnum["Inpatient"] = "Inpatient";
    PatientEligibilityBenefitsEnum["Outpatient"] = "Outpatient";
    PatientEligibilityBenefitsEnum["Maternity"] = "Maternity";
    PatientEligibilityBenefitsEnum["Dental"] = "Dental";
    PatientEligibilityBenefitsEnum["Emergency"] = "Emergency";
    PatientEligibilityBenefitsEnum["Optical"] = "Optical";
    PatientEligibilityBenefitsEnum["Pharmacy"] = "Pharmacy";
    PatientEligibilityBenefitsEnum["Laboratory"] = "Laboratory";
    PatientEligibilityBenefitsEnum["Diagnostic"] = "Diagnostic";
    PatientEligibilityBenefitsEnum["Radiology"] = "Radiology";
})(PatientEligibilityBenefitsEnum || (PatientEligibilityBenefitsEnum = {}));
