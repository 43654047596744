import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import React, { FC, useEffect, useRef, useState } from "react";
import {
  AppointmentCancelForm,
  AppointmentCancelFormRef,
  IAppointmentCancelFormEvent,
} from "../../forms/AppointmentCancel/AppointmentCancelForm";

type IAppointmentCancelModalData = {
  id: string;
};

type IOpen = (data: IAppointmentCancelModalData) => void;
type IClose = () => void;

export const AppointmentCancelModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type AppointmentCancelProps = {
  isLoading?: boolean;
  onChange: (event: IAppointmentCancelFormEvent) => void;
};

export const AppointmentCancelModal: FC<AppointmentCancelProps> = props => {
  const { isLoading, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IAppointmentCancelModalData>();

  const { t } = useTranslation("admin");

  const appointmentCancelFormRef = useRef<AppointmentCancelFormRef>(null);

  const onCancelClick = () => {
    appointmentCancelFormRef?.current?.submit();
  };

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    AppointmentCancelModalApi.open = open;
    AppointmentCancelModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Appointment Cancel"),
        onClose: close,
      }}
      DialogActionsProps={{
        children: (
          <Button
            color={"error"}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onCancelClick}
          >
            {t("Cancel")}
          </Button>
        ),
      }}
    >
      <AppointmentCancelForm id={data?.id!} ref={appointmentCancelFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
