import { gql } from '@apollo/client';
export const OptimaPriorRequestNodeFragmentFragmentDoc = gql `
    fragment OptimaPriorRequestNodeFragment on TransactionPriorRequest {
  id
  transactionId
  transactionDate
  transactionType
  encounterStart
  encounterStartType
  encounterEnd
  encounterType
  patientId
  memberId
  emiratesIDNumber
  facilityId
  payerId
  receiverId
  gross
  net
  source
  Branch {
    nameAr
    name
  }
  priorAuthorization {
    id
    hasClaimActivities
    status
    comments
    denialCode
    end
    start
    idPayer
    limit
    result
  }
  sender {
    name
    id
  }
  encounterTypeLookup {
    name
    type
  }
  facility {
    id
    name
  }
  payer {
    id
    name
  }
  receiver {
    id
    name
  }
  result
}
    `;
