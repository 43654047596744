import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { healthProgramNetworksRoute, healthProgramNetworksPaths } from "./HealthProgramNetworksPaths";
import {
  HealthProgramNetworkListContainer,
  HealthProgramNetworkCreateContainer,
  HealthProgramNetworkUpdateContainer,
  HealthProgramNetworkProviderListContainer,
  HealthProgramNetworkProviderCreateContainer,
  HealthProgramNetworkProviderUpdateContainer,
  HealthProgramNetworkProviderProfessionalListContainer,
} from "../containers";

export const healthProgramNetworksRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "health-program-networks-routes",
    text: i18n.t("Networks", { ns: "admin" }),
    route: healthProgramNetworksRoute,
    subItems: [
      {
        id: "health-program-network-list-route",
        route: healthProgramNetworksPaths.list.route,
        fullPath: healthProgramNetworksPaths.list.fullPath,
        element: <HealthProgramNetworkListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-program-network-create-route",
        route: healthProgramNetworksPaths.create.route,
        fullPath: healthProgramNetworksPaths.create.fullPath,
        hidden: true,
        element: <HealthProgramNetworkCreateContainer />,
      },
      {
        id: "health-program-network-update-route",
        route: healthProgramNetworksPaths.update.route,
        fullPath: healthProgramNetworksPaths.update.fullPath,
        hidden: true,
        element: <HealthProgramNetworkUpdateContainer />,
      },
      {
        id: "health-program-network-provider-list-route",
        route: healthProgramNetworksPaths.providerList.route,
        fullPath: healthProgramNetworksPaths.providerList.fullPath,
        hidden: true,
        element: <HealthProgramNetworkProviderListContainer />,
      },
      {
        id: "health-program-network-provider-create-route",
        route: healthProgramNetworksPaths.providerCreate.route,
        fullPath: healthProgramNetworksPaths.providerCreate.fullPath,
        hidden: true,
        element: <HealthProgramNetworkProviderCreateContainer />,
      },
      {
        id: "health-program-network-provider-update-route",
        route: healthProgramNetworksPaths.providerUpdate.route,
        fullPath: healthProgramNetworksPaths.providerUpdate.fullPath,
        hidden: true,
        element: <HealthProgramNetworkProviderUpdateContainer />,
      },
      {
        id: "health-program-network-provider-professional-list-route",
        route: healthProgramNetworksPaths.providerProfessionalList.route,
        fullPath: healthProgramNetworksPaths.providerProfessionalList.fullPath,
        hidden: true,
        element: <HealthProgramNetworkProviderProfessionalListContainer />,
      },
    ],
  };
};
