/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramBenefitGroupListQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.BenefitFilterInput>;
}>;


export type HealthProgramBenefitGroupListQuery = { __typename?: 'Query', healthProgramBenefitGroups?: Array<{ __typename?: 'BenefitGroup', id: string, name?: string | null } | null> | null };


export const HealthProgramBenefitGroupListDocument = gql`
    query HealthProgramBenefitGroupList($filter: BenefitFilterInput) {
  healthProgramBenefitGroups(filter: $filter) {
    id
    name
  }
}
    `;

/**
 * __useHealthProgramBenefitGroupListQuery__
 *
 * To run a query within a React component, call `useHealthProgramBenefitGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramBenefitGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramBenefitGroupListQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramBenefitGroupListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramBenefitGroupListQuery, HealthProgramBenefitGroupListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramBenefitGroupListQuery, HealthProgramBenefitGroupListQueryVariables>(HealthProgramBenefitGroupListDocument, options);
      }
export function useHealthProgramBenefitGroupListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramBenefitGroupListQuery, HealthProgramBenefitGroupListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramBenefitGroupListQuery, HealthProgramBenefitGroupListQueryVariables>(HealthProgramBenefitGroupListDocument, options);
        }
export type HealthProgramBenefitGroupListQueryHookResult = ReturnType<typeof useHealthProgramBenefitGroupListQuery>;
export type HealthProgramBenefitGroupListLazyQueryHookResult = ReturnType<typeof useHealthProgramBenefitGroupListLazyQuery>;
export type HealthProgramBenefitGroupListQueryResult = Apollo.QueryResult<HealthProgramBenefitGroupListQuery, HealthProgramBenefitGroupListQueryVariables>;