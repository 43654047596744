import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useHealthProgramNetworkCreateMutation } from "../../gql";
import { healthProgramNetworksPaths } from "../../constants";
import { convertHealthProgramNetworkFormValuesToBackEndValues } from "../../others";
import { useSetHealthProgramNetworksBreadcrumbs } from "../../hooks";
import {
  HealthProgramNetworkUpsertForm,
  IHealthProgramNetworkUpsertFormEvent,
} from "../../forms/HealthProgramNetworkUpsert/HealthProgramNetworkUpsertForm";

export const HealthProgramNetworkCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createHealthProgramNetwork, { loading: isSubmitting }] = useHealthProgramNetworkCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramNetworkCreate?.entity?.id) {
        succeeded(t("Network created successfully"));
        navigate(healthProgramNetworksPaths.list.fullPath);
      } else {
        failed(t("Network creation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramNetworkUpsertFormChange = (event: IHealthProgramNetworkUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramNetworkFormValuesToBackEndValues(event.payload.values);

      createHealthProgramNetwork({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetHealthProgramNetworksBreadcrumbs("CREATE");

  return (
    <HealthProgramNetworkUpsertForm
      buttonLabel={t("Create")}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramNetworkUpsertFormChange}
    />
  );
};
