import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Button, CustomDialog, ShowButton } from "@toolkit/ui";
import { CredentialForm } from "./CredentialForm";
import { useCredentialDialog } from "./useCredentialDialog";
export const CredentialDialog = props => {
    const { targetId, type, title, isDisabled } = props;
    const { t } = useTranslation("domains");
    const { open, handleOpen, handleClose, isLoading, isSubmitting, credential, form, onSubmit, handleSubmit } = useCredentialDialog({
        targetId,
        type,
        t,
    });
    return (_jsx(CustomDialog, { type: 'base', open: open, DialogTitleProps: {
            onClose: handleClose,
            title,
        }, DialogContentProps: {
            sx: { minHeight: "unset" },
        }, button: _jsx(ShowButton, { disabled: isDisabled, onClick: handleOpen }), DialogActionsProps: {
            children: (_jsxs(_Fragment, { children: [_jsx(Button, { color: "primary", onClick: handleSubmit, type: 'submit', form: 'custom-dialog-form', size: 'large', variant: 'contained', disabled: isLoading || isSubmitting, children: t("Save") }), _jsx(Button, { variant: 'contained', size: 'large', color: "inherit", onClick: handleClose, children: t("Cancel") })] })),
        }, children: _jsx(CredentialForm, { credential: credential, isLoading: isLoading, isSubmitting: isSubmitting, onSubmit: onSubmit, form: form }) }));
};
