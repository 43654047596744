import { FormAutocomplete, FormDatePickerField, FormPhoneInput, FormTextField, Grid } from "@toolkit/ui";
import { patientIdTypeOptions, userGenderOptions } from "@health/enum-options";
import { HealthProgramMemberListsAutocomplete, SystemCodeAutocomplete } from "@health/autocompletes";
import React, { FC } from "react";
import { useTranslation } from "@toolkit/i18n";
import { CodeSystemCode } from "@health/queries/types";

type HealthProgramMemberInformationFormProps = {
  isUpdateMode: boolean;
  memberListId: string | null;
};

export const HealthProgramMemberInformationForm: FC<HealthProgramMemberInformationFormProps> = props => {
  const { isUpdateMode, memberListId } = props;

  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"firstName"} label={t("First Name")} placeholder={t("First Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"lastName"} label={t("Last Name")} placeholder={t("Last Name")} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"gender"} label={t("Gender")} options={userGenderOptions} />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField
          name={"dateOfBirth"}
          label={t("Date of Birth")}
          placeholder={t("dd/mm/yyyy")}
          datePickerProps={{ disabled: isUpdateMode }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"nationalId"} label={t("Emirates Id")} placeholder={t("Emirates Id")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"iOHealthId"} label={t("iO Health Id")} placeholder={t("iO Health Id")} disabled={isUpdateMode} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"patientIdType"} label={t("ID Type")} options={patientIdTypeOptions} />
      </Grid>

      <Grid item xs={4}>
        <SystemCodeAutocomplete
          name={"nationality"}
          isCodeHidden
          label={t("Nationality")}
          filter={{ codeSystemCode: CodeSystemCode.Nationality }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField
          name={"documentExpiryDate"}
          label={t("Document Expiry Date")}
          placeholder={t("Document Expiry Date")}
          datePickerProps={{ disablePast: true }}
        />
      </Grid>

      {!memberListId && !isUpdateMode && (
        <Grid item xs={4}>
          <HealthProgramMemberListsAutocomplete name={"memberLists"} multiple filter={{ isActive: true }} />
        </Grid>
      )}

      <Grid item xs={4}>
        <FormPhoneInput name={"phoneNumber"} label={t("Phone Number")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"email"} label={t("Email")} placeholder={t("Email")} />
      </Grid>
    </Grid>
  );
};
