/* eslint-disable max-lines */
import React, { useState } from "react";
import { Box, Button, TextField, Typography, useTheme } from "../base/mui";
import { CustomIcon, paths } from "../components";
import * as AllIcons from "./icons";

interface IconsExplorerProps {
  icons?: Record<string, React.ComponentType>;
}

const IconsExplorer: React.FC<IconsExplorerProps> = ({ icons = {} }) => {
  const theme = useTheme();

  const allIconNames = Object.keys(AllIcons);
  const pathIconNames = Object.keys(paths);
  const additionalIconNames = Object.keys(icons);

  const allIcons = [
    ...allIconNames.map(name => ({ name, source: "all" })),
    ...pathIconNames.map(name => ({ name, source: "path" })),
    ...additionalIconNames.map(name => ({ name, source: "additional" })),
  ];

  const [value, setValue] = useState("");
  const [showUsage, setShowUsage] = useState<Record<string, boolean>>({
    all: false,
    path: false,
    additional: false,
  });

  const filteredIcons = value ? allIcons.filter(icon => icon.name.toLowerCase().includes(value.toLowerCase())) : allIcons;

  const handleChangeIcons = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleToggleUsage = (section: string) => {
    setShowUsage(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const getBackgroundColor = (source: string) => {
    switch (source) {
      case "all":
        return theme.palette.gray[300];
      case "path":
        return theme.palette.secondary[300];
      case "additional":
        return theme.palette.yellow[300];
      default:
        return theme.palette.background.default;
    }
  };

  const getIconComponent = (name: string, source: string) => {
    switch (source) {
      case "all":
        return (AllIcons as any)[name];
      case "path":
        return () => <CustomIcon icon={name as any} width={"calc(100% -30px)!important"} height={"calc(100% -40px)!important"} />;
      case "additional":
        return icons[name] || (() => null);
      default:
        return () => null;
    }
  };

  const renderUsageInstruction = (sectionSource: string) => {
    switch (sectionSource) {
      case "all":
        return (
          <Typography variant='body2' sx={{ color: theme.palette.text.primary }}>
            <Box component='code' sx={{ display: "block", backgroundColor: theme.palette.action.hover, p: 1, borderRadius: 1, mt: 1 }}>
              {`import { IconName } from '@toolkit/ui';`}
              <br />
              {`<IconName />`}
            </Box>
          </Typography>
        );
      case "path":
        return (
          <Typography variant='body2' sx={{ color: theme.palette.text.primary }}>
            <Box component='code' sx={{ display: "block", backgroundColor: theme.palette.action.hover, p: 1, borderRadius: 1, mt: 1 }}>
              {`import { CustomIcon } from '@toolkit/ui';`}
              <br />
              {`<CustomIcon icon="IconName" />`}
            </Box>
          </Typography>
        );
      case "additional":
        return (
          <Typography variant='body2' sx={{ color: theme.palette.text.primary }}>
            <Box component='code' sx={{ display: "block", backgroundColor: theme.palette.action.hover, p: 1, borderRadius: 1, mt: 1 }}>
              {`import {IconName} from @/components`}
              <br />
              {`<IconName />`}
            </Box>
          </Typography>
        );
      default:
        return null;
    }
  };

  const sections = [
    { title: "toolkit Icons", source: "all" },
    { title: "toolkit Icons Path's", source: "path" },
    { title: "project Icons", source: "additional" },
  ];

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box mt={3}>
        <TextField placeholder='Search Icons' value={value} onChange={handleChangeIcons} />
      </Box>
      {sections.map(section => {
        const sectionIcons = filteredIcons.filter(icon => icon.source === section.source);

        return (
          sectionIcons?.length > 0 && (
            <Box
              key={section.source}
              sx={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 2,
                padding: 2,
                backgroundColor: theme.palette.background.paper,
                mb: 2,
              }}
            >
              <Typography variant='h6' sx={{ mb: 1, color: theme.palette.text.secondary, fontSize: theme.mixins.fonts.fontSize.lg }}>
                {section.title}
              </Typography>

              <Button onClick={() => handleToggleUsage(section.source)} sx={{ mb: 1 }}>
                {showUsage[section.source] ? "Hide Usage" : "See How to Use"}
              </Button>

              {showUsage[section.source] && renderUsageInstruction(section.source)}

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  rowGap: 2,
                  columnGap: 2,
                  justifyContent: sectionIcons.length > 0 ? "space-between" : "center",
                }}
              >
                {sectionIcons.map((icon, index) => {
                  const IconComponent = getIconComponent(icon.name, icon.source);
                  return (
                    <Box
                      key={`${icon.name}-${icon.source}-${index}`}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100px",
                        height: "100px",
                        padding: "15x",
                        overflowWrap: "break-word",
                        "& svg": {
                          width: "70px",
                          height: "70px",
                          backgroundColor: getBackgroundColor(icon.source),
                          borderRadius: "8px",
                          padding: "4px",
                        },
                      }}
                    >
                      <IconComponent />
                      <Typography sx={{ width: "100%" }} fontSize={8} mt={1}>
                        {icon.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )
        );
      })}
      {filteredIcons?.length === 0 && <Typography>No icons found</Typography>}
    </Box>
  );
};

export default IconsExplorer;
