import { useEffect, useState } from "react";

export const useGridFilter = (
  doFilter: (filterData: Record<string, any>) => void,
  filterInput: Record<string, any>,
  hideFilterInput?: boolean,
  isReadOnlyFilters?: boolean
) => {
  const [isOpen, setIsFilterOpened] = useState(false);
  const [activeFilters, setActiveFilters] = useState<Record<string, any>>({});
  const [requestedFilters, setRequestedFilters] = useState<Record<string, any>>({});
  const [filters, setFilters] = useState<Record<string, any>>({});
  const [formattedFiltersValues, setFormattedFiltersValues] = useState<Record<string, unknown>>({});

  const handleApplyFilters = (data: Record<string, any>, filters: Record<string, any>, requestedData: Record<string, any>) => {
    hideFilterInput ? setActiveFilters({ ...filters }) : setActiveFilters({ ...filterInput, ...filters });
    !isReadOnlyFilters && doFilter({ ...filterInput, ...data });
    setRequestedFilters({ ...filterInput, ...data });
    setFilters(requestedData);
    setFormattedFiltersValues(data);
  };

  const handleDismissFilters = () => {
    setActiveFilters({});
  };

  const handleToggleFilters = () => {
    setIsFilterOpened(!isOpen);
  };

  useEffect(() => {
    !hideFilterInput && setActiveFilters(filterInput);
  }, [hideFilterInput, JSON.stringify(filterInput)]);

  const staticFiltersProps = {
    isOpen: !!isOpen,
    activeFilters,
    onToggleDialog: handleToggleFilters,
    onApplyFilters: handleApplyFilters,
    onDismissFilters: handleDismissFilters,
  };

  const activeFiltersProps = {
    filters,
    requestedFilters,
    activeFilters: activeFilters || [],
    onOpenFilters: handleToggleFilters,
    formattedFiltersValues,
  };

  return {
    staticFiltersProps,
    activeFiltersProps,
  };
};
