/* eslint-disable no-nested-ternary */
import { FC, useState } from "react";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, useAddToast } from "@toolkit/ui";
import { hasPermission } from "@toolkit/core";
import { PermissionEnum } from "@health/queries/types";
import { useHealthProgramActivationMutation } from "../../gql";

type HealthProgramActivationProps = {
  id: string;
  isActive: boolean;
};

export const HealthProgramActivation: FC<HealthProgramActivationProps> = props => {
  const { id, isActive } = props;

  const [active, setActive] = useState(isActive);

  const { t } = useTranslation("admin");
  const { succeeded, failed } = useAddToast();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const [activateHealthProgram, { loading: isSubmitting }] = useHealthProgramActivationMutation({
    onCompleted: data => {
      if (data?.healthProgramUpdateActiveStatus?.healthProgram?.id) {
        setActive(!!data?.healthProgramUpdateActiveStatus?.healthProgram?.isActive);
        succeeded(t("Health Program updated successfully"));
      } else {
        failed(t("Health Program update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const handleChangeToggle = () => {
    activateHealthProgram({
      variables: { id },
    });
  };

  return (
    <>
      {hasManageHealthProgramsPermission ? (
        <CustomToggleButton
          disabled={isSubmitting}
          isLoading={isSubmitting}
          isMarginDisabled
          checked={active}
          onChange={handleChangeToggle}
        />
      ) : active ? (
        t("Yes")
      ) : (
        t("No")
      )}
    </>
  );
};
