import { createPathDefinition } from "@toolkit/core";

export const healthProgramNetworksRoute = "/health-programs/networks";

export const healthProgramNetworksPaths = createPathDefinition(healthProgramNetworksRoute, {
  list: "",
  create: "new",
  update: ":networkId",
  providerList: ":networkId/providers",
  providerCreate: ":networkId/providers/new",
  providerUpdate: ":networkId/providers/:providerId",
  providerProfessionalList: ":networkId/providers/:providerId/professionals",
});
