import { FC } from "react";
import { List, ListItem, ListItemText, Typography } from "@toolkit/ui";
import { useHealthProgramNetworkProviderServicesStyle } from "./useHealthProgramNetworkProviderServicesStyle";

type HealthProgramNetworkProviderServicesProps = {
  services: string[];
};

export const HealthProgramNetworkProviderServices: FC<HealthProgramNetworkProviderServicesProps> = props => {
  const { services } = props;

  const { classes } = useHealthProgramNetworkProviderServicesStyle();

  return (
    <List className={classes.list}>
      {services?.map(item => (
        <ListItem key={item} className={classes.listItem}>
          <ListItemText primary={<Typography className={classes.listItemText}> {item} </Typography>} />
        </ListItem>
      ))}
    </List>
  );
};
