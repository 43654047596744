import { makeStyles } from "@toolkit/ui";
export const useClaimActivitiesDrawerStyles = makeStyles()(theme => ({
    label: {
        fontSize: theme.mixins.fonts.fontSize.md,
        fontWeight: theme.mixins.fonts.fontWeight.medium,
    },
    value: {
        fontSize: theme.mixins.fonts.fontSize.md,
    },
    drawer: {
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            width: "80%",
        },
        padding: theme.spacing(2),
    },
    sectionTitle: {
        fontSize: theme.mixins.fonts.fontSize.lg,
        fontWeight: theme.mixins.fonts.fontWeight.bold,
    },
    sectionContainer: {
        display: "grid",
        gap: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(1),
        gridTemplateColumns: "repeat(auto-fill, minmax(225px, 1fr))",
    },
    pending: {
        fontWeight: theme.mixins.fonts.fontWeight.medium,
        color: theme.palette.stale.main,
        textAlign: "start",
        paddingInlineStart: "15px",
    },
}));
