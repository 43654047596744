import { useTranslation } from "@toolkit/i18n";
import { formatGraphQLError } from "@toolkit/apollo";
import { useNavigate } from "react-router-dom";
import { useAddToast } from "@toolkit/ui";
import { IPayerUpsertFormEvent, PayerUpsertForm } from "../../forms/PayerUpsert/PayerUpsertForm";
import { usePayerCreateMutation } from "../../gql";
import { convertPayerFormValuesToBackEndValues } from "../../others";
import { payersPaths } from "@/pages/Payers/constants";
import { useSetPayersBreadcrumbs } from "@/pages/Payers/hooks";

export const PayerCreateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const [createPayer, { loading: isSubmitting }] = usePayerCreateMutation({
    onCompleted: mutationData => {
      if (mutationData?.payerCreate?.payer?.id) {
        succeeded(t("Payer created successfully"));
        navigate(payersPaths.list.fullPath);
      } else {
        failed(t("Payer creation failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onPayerUpsertFormChange = (event: IPayerUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertPayerFormValuesToBackEndValues(event.payload.values);

      createPayer({
        variables: {
          input: values,
        },
      });
    }
  };

  useSetPayersBreadcrumbs("CREATE");

  return <PayerUpsertForm buttonLabel={t("Create")} isSubmitting={isSubmitting} onChange={onPayerUpsertFormChange} />;
};
