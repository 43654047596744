import { Address } from "@health/queries/types";
import { FC } from "react";
import { Box, Typography } from "@toolkit/ui";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";

type HealthProgramNetworkProviderAddressProps = {
  address: Address;
};

export const HealthProgramNetworkProviderAddress: FC<HealthProgramNetworkProviderAddressProps> = props => {
  const { address } = props;

  const { t } = useTranslation("admin");

  const streetAddress = [
    pickLocalizedValue(address?.streetAddress1, address?.streetAddress1Ar),
    pickLocalizedValue(address?.streetAddress2, address?.streetAddress2Ar),
  ]
    ?.filter(Boolean)
    ?.join(", ");

  const city = pickLocalizedValue(address?.city?.name, address?.city?.nameAr);

  return (
    <Box>
      {streetAddress && (
        <Typography>
          {t("Street")}: {streetAddress}
        </Typography>
      )}

      {city && (
        <Typography>
          {t("City")}: {city}
        </Typography>
      )}
    </Box>
  );
};
