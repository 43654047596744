import { z } from "zod";
import { createZodAutocompleteFromType } from "@toolkit/core";
export const healthProgramNetworkProviderSchema = createZodAutocompleteFromType({
    id: z.string(),
    provider: z
        .object({
        id: z.string(),
        name: z.string(),
        nameAr: z.string().nullish(),
    })
        .nullish(),
});
