import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import React, { FC, useEffect, useRef, useState } from "react";
import { VisitCancelForm, VisitCancelFormRef, IVisitCancelFormEvent } from "../../forms/VisitCancel/VisitCancelForm";

type IVisitCancelModalData = {
  id: string;
};

type IOpen = (data: IVisitCancelModalData) => void;
type IClose = () => void;

export const VisitCancelModalApi: { open: IOpen; close: IClose } = {
  open: () => {},
  close: () => {},
};

type VisitCancelModalProps = {
  isLoading?: boolean;
  onChange: (event: IVisitCancelFormEvent) => void;
};

export const VisitCancelModal: FC<VisitCancelModalProps> = props => {
  const { isLoading, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<IVisitCancelModalData>();

  const { t } = useTranslation("admin");

  const visitCancelFormRef = useRef<VisitCancelFormRef>(null);

  const onCancelClick = () => {
    visitCancelFormRef?.current?.submit();
  };

  const open: IOpen = _data => {
    setIsOpen(true);
    setData(_data);
  };

  const close: IClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    VisitCancelModalApi.open = open;
    VisitCancelModalApi.close = close;
  }, []);

  return (
    <CustomDialog
      type={"base"}
      open={isOpen}
      DialogTitleProps={{
        title: t("Visit Cancel"),
        onClose: close,
      }}
      DialogActionsProps={{
        children: (
          <Button
            color={"error"}
            disabled={isLoading}
            endIcon={isLoading ? <CircularProgress color={"inherit"} size={20} /> : null}
            onClick={onCancelClick}
          >
            {t("Cancel")}
          </Button>
        ),
      }}
    >
      <VisitCancelForm id={data?.id!} ref={visitCancelFormRef} onChange={onChange} />
    </CustomDialog>
  );
};
