import { getBranchesAutocompleteFilter, getVendorsAutocompleteFilter } from "@health/autocompletes";
import { CredentialDialog } from "@health/domains";
import { getAutocompleteEnumFilter, vendorUserTypeOptionsMap } from "@health/enum-options";
import { OrderDirection, UserSortField } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { ConnectionDocumentNode, CustomTableColumnProps, TruncateTypography } from "@toolkit/ui";
import { GetAdminUsersQuery } from "pages/UserManagement/gql";
import { useMemo } from "react";
import { ProviderUserActiveToggleButton } from "./ProviderUserActiveToggleButton.component";

export type UsersManagementColumnsTypes = CustomTableColumnProps<ConnectionDocumentNode<GetAdminUsersQuery, "users">>[];

export const useProviderUserManagementColumns = (canManageUser: boolean): UsersManagementColumnsTypes => {
  const { t } = useTranslation("admin");
  return useMemo(() => {
    return [
      {
        key: "name",
        header: t("Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "search",
        },
      },
      {
        key: "status",
        header: t("Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("UserStatus", "status"),
      },
      {
        key: "appRole",
        header: t("App Role"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("AppRoleType", "appRole"),
      },
      {
        key: "firstName",
        header: t("First Name"),
        accessor: row => <TruncateTypography text={row?.firstName!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.FirstName,
      },
      {
        key: "lastName",
        header: t("Last Name"),
        accessor: row => <TruncateTypography text={row?.lastName!} />,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.LastName,
      },
      {
        key: "email",
        header: t("Email"),
        accessor: "email",
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: UserSortField.Email,
      },
      {
        key: "mobile",
        header: t("Mobile"),
        accessor: "mobile",
      },
      {
        key: "credential",
        header: t("eRxHub Credential"),
        accessor: ({ id, isActive }) => (
          <CredentialDialog isDisabled={!isActive} targetId={id || ""} type='clinician' title={t("eRxHub Credential")} />
        ),
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: row => {
          return <ProviderUserActiveToggleButton disabled={!canManageUser} id={row?.id as string} isActive={row?.isActive as boolean} />;
        },
      },
      {
        key: "isVendorAdmin",
        header: t("Is Vendor Admin"),
        accessor: row => (row?.isVendorAdmin === true ? t("Yes") : t("No")),
      },
      {
        key: "vendorUserType",
        header: t("Vendor User Type"),
        accessor: ({ vendorUserType }) => vendorUserTypeOptionsMap[vendorUserType!]?.label || "",
        filter: getAutocompleteEnumFilter("VendorUserType", "vendorUserType"),
      },
      {
        key: "branchId",
        header: t("Branch"),
        showOnlyForFilterField: true,
        filter: getBranchesAutocompleteFilter({ name: "branchId" }),
      },
      {
        key: "Vendor",
        header: t("Vendor"),
        showOnlyForFilterField: true,
        filter: getVendorsAutocompleteFilter({ name: "vendorId" }),
      },
      {
        key: "dateJoinedFrom",
        header: t("Date Joined From"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.gte",
        },
      },
      {
        key: "dateJoined",
        header: t("Date Joined To"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateJoined.lte",
        },
      },
    ];
  }, [t]);
};
