export const convertCredentialFormValuesToBackEndValues = (values) => {
    return {
        userName: values.username,
        password: values.password || "",
        license: values.license || "",
    };
};
export const convertCredentialToFormValues = (credential) => {
    return {
        username: credential === null || credential === void 0 ? void 0 : credential.username,
        password: "",
        license: credential.license,
    };
};
