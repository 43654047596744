import { zodSchema } from "@health/autocompletes";
import { z } from "zod";

export const healthProgramNetworkProviderUpsertFormSchema = z.object({
  vendor: zodSchema.vendor,
  services: z.array(z.string()),
  isVirtualCallEnabled: z.boolean(),
  isActive: z.boolean(),
});

export type IHealthProgramNetworkProviderUpsertFormValues = z.infer<typeof healthProgramNetworkProviderUpsertFormSchema>;

export const healthProgramNetworkProviderUpsertFormDefaultValues: Partial<IHealthProgramNetworkProviderUpsertFormValues> = {
  vendor: undefined,
  services: [],
  isVirtualCallEnabled: false,
  isActive: false,
};
