import { HealthProgramNetworkProviderProfessional } from "@health/queries/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { HealthProgramNetworkProviderProfessionals } from "../../components/HealthProgramNetworkProviderProfessionals/HealthProgramNetworkProviderProfessionals";

type HealthProgramNetworkProviderProfessionalsModalProps = {
  professionals: HealthProgramNetworkProviderProfessional[];
};

export const HealthProgramNetworkProviderProfessionalsModal: FC<HealthProgramNetworkProviderProfessionalsModalProps> = props => {
  const { professionals } = props;

  const { t } = useTranslation("admin");

  return (
    <CustomDialog
      type={"info"}
      button={<ShowButton disabled={!professionals || !professionals?.length} />}
      title={t("Network Provider Professionals")}
    >
      <HealthProgramNetworkProviderProfessionals professionals={professionals} />
    </CustomDialog>
  );
};
