/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentCancelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.CancelInput;
}>;


export type AppointmentCancelMutation = { __typename?: 'Mutation', appointmentCancelBySupportTeam?: { __typename?: 'AppointmentApprovalAction', appointment?: { __typename?: 'Appointment', id: string } | null } | null };


export const AppointmentCancelDocument = gql`
    mutation AppointmentCancel($id: ID!, $input: CancelInput!) {
  appointmentCancelBySupportTeam(id: $id, input: $input) {
    appointment {
      id
    }
  }
}
    `;
export type AppointmentCancelMutationFn = Apollo.MutationFunction<AppointmentCancelMutation, AppointmentCancelMutationVariables>;

/**
 * __useAppointmentCancelMutation__
 *
 * To run a mutation, you first call `useAppointmentCancelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppointmentCancelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appointmentCancelMutation, { data, loading, error }] = useAppointmentCancelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppointmentCancelMutation(baseOptions?: Apollo.MutationHookOptions<AppointmentCancelMutation, AppointmentCancelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppointmentCancelMutation, AppointmentCancelMutationVariables>(AppointmentCancelDocument, options);
      }
export type AppointmentCancelMutationHookResult = ReturnType<typeof useAppointmentCancelMutation>;
export type AppointmentCancelMutationResult = Apollo.MutationResult<AppointmentCancelMutation>;
export type AppointmentCancelMutationOptions = Apollo.BaseMutationOptions<AppointmentCancelMutation, AppointmentCancelMutationVariables>;