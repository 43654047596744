import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "@health/enum-options";

export enum CareForType {
  Acne = "Acne",
  Allergies = "Allergies",
  Bronchitis = "Bronchitis",
  ColdAndFlu = "Cold & Flu",
  ColdSores = "Cold Sores",
  Fatigue = "Fatigue",
  GeneralPain = "General Pain",
  PinkEye = "Pink-eye",
  Rashes = "Rashes",
  SinusInfections = "Sinus Infections",
  StomachPain = "Stomach Pain",
  UTI = "UTI",
}

export const careForTypeOptionsMap: IEnumToOptionsMap<CareForType> = {
  [CareForType.Acne]: {
    key: CareForType.Acne,
    get label() {
      return i18n.t("Acne", { ns: "admin" });
    },
    value: CareForType.Acne,
  },
  [CareForType.Allergies]: {
    key: CareForType.Allergies,
    get label() {
      return i18n.t("Allergies", { ns: "admin" });
    },
    value: CareForType.Allergies,
  },
  [CareForType.Bronchitis]: {
    key: CareForType.Bronchitis,
    get label() {
      return i18n.t("Bronchitis", { ns: "admin" });
    },
    value: CareForType.Bronchitis,
  },
  [CareForType.ColdAndFlu]: {
    key: CareForType.ColdAndFlu,
    get label() {
      return i18n.t("Cold & Flu", { ns: "admin" });
    },
    value: CareForType.ColdAndFlu,
  },
  [CareForType.ColdSores]: {
    key: CareForType.ColdSores,
    get label() {
      return i18n.t("Cold Sores", { ns: "admin" });
    },
    value: CareForType.ColdSores,
  },
  [CareForType.Fatigue]: {
    key: CareForType.Fatigue,
    get label() {
      return i18n.t("Fatigue", { ns: "admin" });
    },
    value: CareForType.Fatigue,
  },
  [CareForType.GeneralPain]: {
    key: CareForType.GeneralPain,
    get label() {
      return i18n.t("General Pain", { ns: "admin" });
    },
    value: CareForType.GeneralPain,
  },
  [CareForType.PinkEye]: {
    key: CareForType.PinkEye,
    get label() {
      return i18n.t("Pink-eye", { ns: "admin" });
    },
    value: CareForType.PinkEye,
  },
  [CareForType.Rashes]: {
    key: CareForType.Rashes,
    get label() {
      return i18n.t("Rashes", { ns: "admin" });
    },
    value: CareForType.Rashes,
  },
  [CareForType.SinusInfections]: {
    key: CareForType.SinusInfections,
    get label() {
      return i18n.t("Sinus Infections", { ns: "admin" });
    },
    value: CareForType.SinusInfections,
  },
  [CareForType.StomachPain]: {
    key: CareForType.StomachPain,
    get label() {
      return i18n.t("Stomach Pain", { ns: "admin" });
    },
    value: CareForType.StomachPain,
  },
  [CareForType.UTI]: {
    key: CareForType.UTI,
    get label() {
      return i18n.t("UTI", { ns: "admin" });
    },
    value: CareForType.UTI,
  },
};

export const careForTypeOptions = Object.values(careForTypeOptionsMap);
