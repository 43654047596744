import { makeStyles } from "tss-react/mui";

export const useFormNumberFieldStyle = makeStyles()(() => ({
  inputNoArrows: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "&[type=number]": {
      "-moz-appearance": "textfield",
    },
  },
}));
