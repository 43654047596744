import { useCustomForm, useOpenState } from "@toolkit/core";
import { CredentialFormSchema, credentialFormSchemaDefaultValues } from "./CredentialForm";
import { useGetClinicianCredentialsQuery, useGetPharmacyCredentialsQuery } from "./gql/queries";
import { useSetClinicianCredentialsMutation, useSetPharmacyCredentialsMutation } from "./gql/mutations";
import { useAddToast } from "@toolkit/ui";
import { formatGraphQLError } from "@toolkit/apollo";
import { useEffect } from "react";
import { convertCredentialToFormValues, convertCredentialFormValuesToBackEndValues } from "./utils";
export const useCredentialDialog = (props) => {
    const { targetId, type, t } = props;
    const { open, handleOpen, handleClose } = useOpenState();
    const { failed, succeeded } = useAddToast();
    const form = useCustomForm({
        schema: CredentialFormSchema,
        defaultValues: credentialFormSchemaDefaultValues,
        mode: "onChange",
    });
    const { watch } = form;
    const destination = watch("destination");
    const { data: pharmacyCredentialData, loading: isPharmacyLoading } = useGetPharmacyCredentialsQuery({
        variables: {
            destinationType: destination === null || destination === void 0 ? void 0 : destination.value,
            branch: targetId,
        },
        skip: !destination || !targetId || type !== "pharmacy" || !open,
        fetchPolicy: "network-only",
    });
    const { data: clinicianCredentialData, loading: isClinicianLoading } = useGetClinicianCredentialsQuery({
        variables: {
            destinationType: destination === null || destination === void 0 ? void 0 : destination.value,
            user: targetId,
        },
        skip: !destination || !targetId || type !== "clinician" || !open,
        fetchPolicy: "network-only",
    });
    const pharmacyCredentials = pharmacyCredentialData === null || pharmacyCredentialData === void 0 ? void 0 : pharmacyCredentialData.getPharmacyCredentials;
    const clinicianCredentials = clinicianCredentialData === null || clinicianCredentialData === void 0 ? void 0 : clinicianCredentialData.getClinicianCredentials;
    const isLoading = isPharmacyLoading || isClinicianLoading;
    const credential = type === "pharmacy"
        ? {
            username: (pharmacyCredentials === null || pharmacyCredentials === void 0 ? void 0 : pharmacyCredentials.userName) || "",
            password: "",
            license: (pharmacyCredentials === null || pharmacyCredentials === void 0 ? void 0 : pharmacyCredentials.license) || "",
        }
        : {
            username: (clinicianCredentials === null || clinicianCredentials === void 0 ? void 0 : clinicianCredentials.userName) || "",
            password: "",
            license: (clinicianCredentials === null || clinicianCredentials === void 0 ? void 0 : clinicianCredentials.license) || "",
        };
    const [SetPharmacyCredentials, { loading: isSetPharmacyLoading }] = useSetPharmacyCredentialsMutation({
        onCompleted: () => {
            succeeded(t("Credentials updated Successfully"));
            handleClose();
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const [SetClinicianCredentials, { loading: isSetClinicianLoading }] = useSetClinicianCredentialsMutation({
        onCompleted: () => {
            succeeded(t("Credentials updated Successfully"));
            handleClose();
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const isSubmitting = isSetPharmacyLoading || isSetClinicianLoading;
    useEffect(() => {
        form.setValues(convertCredentialToFormValues(credential));
    }, [JSON.stringify(credential)]);
    const onSubmit = (values) => {
        type === "pharmacy" &&
            SetPharmacyCredentials({
                variables: {
                    destinationType: destination === null || destination === void 0 ? void 0 : destination.value,
                    pharmacyCredentials: Object.assign({ branch: targetId }, convertCredentialFormValuesToBackEndValues(values)),
                },
            });
        type === "clinician" &&
            SetClinicianCredentials({
                variables: {
                    destinationType: destination === null || destination === void 0 ? void 0 : destination.value,
                    clinicianCredentials: Object.assign({ user: targetId }, convertCredentialFormValuesToBackEndValues(values)),
                },
            });
    };
    const handleSubmit = form.handleSubmit(onSubmit);
    return { open, handleOpen, handleClose, isLoading, isSubmitting, credential, form, onSubmit, handleSubmit };
};
