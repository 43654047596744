/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AppointmentListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.AppointmentFilterInput>;
  sortBy?: Types.InputMaybe<Types.AppointmentSortingInput>;
}>;


export type AppointmentListQuery = { __typename?: 'Query', appointments?: { __typename?: 'AppointmentCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'AppointmentCountableEdge', node: { __typename?: 'Appointment', id: string, type?: Types.AppointmentType | null, status?: Types.AppointmentStatus | null, paymentStatus?: Types.PaymentStatus | null, requestedBy?: Types.RequestedBy | null, requestedTimeSlots?: Array<string | null> | null, suggestedTimeSlots?: Array<string | null> | null, confirmedStartTime?: any | null, confirmedEndTime?: any | null, created?: any | null, cancellationReason?: string | null, doctor?: { __typename?: 'Doctor', id: string, user?: { __typename?: 'User', id: string, fullName?: string | null } | null } | null, branch?: { __typename?: 'Branch', id: string, name: string, nameAr?: string | null, vendor: { __typename?: 'Vendor', id: string, name: string, nameAr?: string | null } } | null, consumer?: { __typename?: 'Patient', id: string, firstName?: string | null, lastName?: string | null, contactNumber?: string | null, email?: string | null, activeHealthProgramMembers?: Array<{ __typename?: 'HealthProgramMember', id: string, insuranceId?: string | null, membershipEnd?: any | null, membershipStart?: any | null, payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } | null> | null } | null } }> } | null };


export const AppointmentListDocument = gql`
    query AppointmentList($first: Int, $last: Int, $before: String, $after: String, $filter: AppointmentFilterInput, $sortBy: AppointmentSortingInput) {
  appointments(
    first: $first
    last: $last
    filter: $filter
    sortBy: $sortBy
    before: $before
    after: $after
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        type
        status
        paymentStatus
        requestedBy
        requestedTimeSlots
        suggestedTimeSlots
        confirmedStartTime
        confirmedEndTime
        created
        cancellationReason
        doctor {
          id
          user {
            id
            fullName
          }
        }
        branch {
          id
          name
          nameAr
          vendor {
            id
            name
            nameAr
          }
        }
        consumer {
          id
          firstName
          lastName
          contactNumber
          email
          activeHealthProgramMembers {
            id
            insuranceId
            membershipEnd
            membershipStart
            payer {
              id
              name
              nameAr
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useAppointmentListQuery__
 *
 * To run a query within a React component, call `useAppointmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useAppointmentListQuery(baseOptions?: Apollo.QueryHookOptions<AppointmentListQuery, AppointmentListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppointmentListQuery, AppointmentListQueryVariables>(AppointmentListDocument, options);
      }
export function useAppointmentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppointmentListQuery, AppointmentListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppointmentListQuery, AppointmentListQueryVariables>(AppointmentListDocument, options);
        }
export type AppointmentListQueryHookResult = ReturnType<typeof useAppointmentListQuery>;
export type AppointmentListLazyQueryHookResult = ReturnType<typeof useAppointmentListLazyQuery>;
export type AppointmentListQueryResult = Apollo.QueryResult<AppointmentListQuery, AppointmentListQueryVariables>;