/* eslint-disable max-statements */
import { z } from "zod";
import { isNil } from "lodash";
import { i18n } from "@toolkit/i18n";
import { IHealthProgramUpsertFormValues } from "../forms/HealthProgramUpsert/HealthProgramUpsertFormSchema";

export const validateProfitShares = (data: IHealthProgramUpsertFormValues, ctx: z.RefinementCtx) => {
  const { platformProfitShare, vendorProfitShare, operatorProfitShare } = data;
  const profitShares = [platformProfitShare, vendorProfitShare, operatorProfitShare];
  const providedProfitShares = profitShares.filter(share => share !== null && share !== undefined);

  if (providedProfitShares.length > 0 && providedProfitShares.length < 3) {
    if (isNil(platformProfitShare)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["platformProfitShare"],
        message: i18n.t("Required"),
      });
    }

    if (isNil(vendorProfitShare)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["vendorProfitShare"],
        message: i18n.t("Required"),
      });
    }

    if (isNil(operatorProfitShare)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["operatorProfitShare"],
        message: i18n.t("Required"),
      });
    }
  } else if (providedProfitShares.length === 3) {
    const total = (platformProfitShare || 0) + (vendorProfitShare || 0) + (operatorProfitShare || 0);

    if (total !== 100) {
      const errorMessage = i18n.t("The sum of Platform Profit, Vendor Profit, and Operator Profit must equal 100");

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["platformProfitShare"],
        message: errorMessage,
      });

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["vendorProfitShare"],
        message: errorMessage,
      });

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["operatorProfitShare"],
        message: errorMessage,
      });
    }
  }
};

export const validateBenefitFields = (data: IHealthProgramUpsertFormValues, ctx: z.RefinementCtx) => {
  !data?.payPerCall &&
    data?.benefits?.forEach((benefit, index) => {
      benefit?.options?.forEach((option, optionIndex) => {
        if (option?.isAdded) {
          const hasName = !!option?.name;
          const hasValue = !isNil(option?.value);

          if (!hasName) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["benefits", index, "options", optionIndex, "name"],
              message: i18n.t("Required"),
            });
          }

          if (!hasValue) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ["benefits", index, "options", optionIndex, "value"],
              message: i18n.t("Required"),
            });
          }
        }
      });
    });
};
