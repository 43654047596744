import { useTranslation } from "@toolkit/i18n";
import { useNavigate } from "react-router-dom";
import { useCustomForm } from "@toolkit/core";
import React, { FC, useEffect } from "react";
import { HealthProgramNetwork } from "@health/queries/types";
import { FormProvider } from "react-hook-form";
import { FormActions, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { PayersAutocomplete } from "@health/autocompletes";
import { healthProgramNetworksPaths } from "../../constants";
import { convertHealthProgramNetworkToFormValues } from "../../others";
import {
  healthProgramNetworkUpsertFormSchema,
  healthProgramNetworkUpsertFormDefaultValues,
  IHealthProgramNetworkUpsertFormValues,
} from "./HealthProgramNetworkUpsertFormSchema";

export type IHealthProgramNetworkUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthProgramNetworkUpsertFormValues;
  };
};

type HealthProgramNetworkUpsertFormProps = {
  healthProgramNetwork?: HealthProgramNetwork;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IHealthProgramNetworkUpsertFormEvent) => void;
};

export const HealthProgramNetworkUpsertForm: FC<HealthProgramNetworkUpsertFormProps> = props => {
  const { healthProgramNetwork, isLoading, isSubmitting, buttonLabel, onChange } = props;

  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const form = useCustomForm<IHealthProgramNetworkUpsertFormValues>({
    defaultValues: healthProgramNetworkUpsertFormDefaultValues,
    schema: healthProgramNetworkUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IHealthProgramNetworkUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(healthProgramNetworksPaths.list.fullPath);
  };

  useEffect(() => {
    if (healthProgramNetwork) {
      const _healthProgramNetwork = convertHealthProgramNetworkToFormValues(healthProgramNetwork);
      setValues(_healthProgramNetwork);
    }
  }, [healthProgramNetwork, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
              onNavigation={handleCancel}
            />
          }
        >
          <FormCard title={t("Network Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormTextField name={"name"} label={t("Name")} placeholder={t("Name")} />
                  </Grid>

                  <Grid item xs={4}>
                    <PayersAutocomplete name={"payer"} filter={{ isActive: true }} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormTextField name={"categoryCode"} label={t("Category Code")} placeholder={t("Category Code")} />
                  </Grid>

                  <Grid item xs={4}>
                    <FormTextField name={"policyNumber"} label={t("Policy Number")} placeholder={t("Policy Number")} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <FormSwitch name={"isActive"} label={t("Is Active")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
