/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PayerUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.PayerInput;
}>;


export type PayerUpdateMutation = { __typename?: 'Mutation', payerUpdate?: { __typename?: 'PayerUpdate', payer?: { __typename?: 'Payer', id: string } | null } | null };


export const PayerUpdateDocument = gql`
    mutation PayerUpdate($id: ID!, $input: PayerInput!) {
  payerUpdate(id: $id, input: $input) {
    payer {
      id
    }
  }
}
    `;
export type PayerUpdateMutationFn = Apollo.MutationFunction<PayerUpdateMutation, PayerUpdateMutationVariables>;

/**
 * __usePayerUpdateMutation__
 *
 * To run a mutation, you first call `usePayerUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayerUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payerUpdateMutation, { data, loading, error }] = usePayerUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayerUpdateMutation(baseOptions?: Apollo.MutationHookOptions<PayerUpdateMutation, PayerUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayerUpdateMutation, PayerUpdateMutationVariables>(PayerUpdateDocument, options);
      }
export type PayerUpdateMutationHookResult = ReturnType<typeof usePayerUpdateMutation>;
export type PayerUpdateMutationResult = Apollo.MutationResult<PayerUpdateMutation>;
export type PayerUpdateMutationOptions = Apollo.BaseMutationOptions<PayerUpdateMutation, PayerUpdateMutationVariables>;