import { useCustomFormContext } from "@toolkit/core";
import { get, isNil } from "lodash";
import React, { FC } from "react";
import { TextField, TextFieldProps } from "../../../base/mui";
import { useFormNumberFieldStyle } from "./useFormNumberFieldStyle";

type FormNumberFieldProps = Omit<TextFieldProps, "defaultValue" | "error" | "helperText" | "type"> & {
  name: string;
  isFloatAllowed?: boolean;
  isArrowsHidden?: boolean;
};

export const FormNumberField: FC<FormNumberFieldProps> = props => {
  const {
    name,
    isFloatAllowed,
    isArrowsHidden,
    variant = "filled",
    size = "small",
    fullWidth = true,
    disabled,
    inputProps,
    ...rest
  } = props;

  const { classes } = useFormNumberFieldStyle();

  const {
    register,
    isFormDisabled,
    formState: { errors },
  } = useCustomFormContext();

  const error = get(errors, name + ".message", "") as string;

  const parseValue = (value: string) => {
    if (isNil(value) || value === "") return;
    return isFloatAllowed ? parseFloat(value) : parseInt(value);
  };

  return (
    <TextField
      {...rest}
      {...register(name, {
        setValueAs: parseValue,
      })}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      type='number'
      error={!!error}
      helperText={error}
      disabled={isFormDisabled || disabled}
      inputProps={{
        ...inputProps,
        step: isFloatAllowed ? "any" : "1",
        className: isArrowsHidden ? classes.inputNoArrows : undefined,
      }}
    />
  );
};
