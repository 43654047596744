import { FormNumberField, Grid, HintComponent } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";

export const HealthProgramParametersCallPointsForm = () => {
  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"normalDemand"}
          label={t("Normal Demand")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            endAdornment: <HintComponent title={t("Total number of points gained per call during normal demand times")} />,
            // readOnly: readOnly,
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={2.4}>
        <FormNumberField
          name={"urgentDemand"}
          label={t("Urgent Demand")}
          isFloatAllowed
          isArrowsHidden
          InputProps={{
            endAdornment: <HintComponent title={t("Total number of points gained per call during urgent (high) demand times")} />,
            // readOnly: readOnly,
          }}
        />
      </Grid>
    </Grid>
  );
};
