import { useTranslation } from "@toolkit/i18n";
import { CustomDialog, ShowButton } from "@toolkit/ui";
import { FC } from "react";
import { HealthProgramNetworks } from "../../components/HealthProgramNetworks/HealthProgramNetworks";

type HealthProgramNetworksModalProps = {
  id: string;
  networksCount: number;
};

export const HealthProgramNetworksModal: FC<HealthProgramNetworksModalProps> = props => {
  const { id, networksCount } = props;

  const { t } = useTranslation("admin");

  return (
    <CustomDialog type={"info"} button={<ShowButton disabled={!networksCount} />} title={t("Health Program Networks")}>
      <HealthProgramNetworks id={id} />
    </CustomDialog>
  );
};
