import { useCallback, useEffect, useMemo } from "react";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { healthProgramNetworksPaths } from "../constants";
import {
  useHealthProgramNetworkBreadcrumbGetQuery,
  useHealthProgramNetworkProviderBreadcrumbGetQuery,
} from "@/pages/HealthProgramNetworks/gql";

type SetHealthProgramNetworksProvidersBreadcrumbsProps = {
  networkId: string;
  providerId?: string;
};

export const useSetHealthProgramNetworksProvidersBreadcrumbs = (
  containerType: "LIST" | "CREATE" | "UPDATE" | "PROFESSIONAL_LIST",
  props: SetHealthProgramNetworksProvidersBreadcrumbsProps
) => {
  const { networkId, providerId } = props;

  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const { data, loading } = useHealthProgramNetworkBreadcrumbGetQuery({
    variables: {
      filter: { ids: [networkId] },
    },
    skip: !networkId,
  });

  const { data: healthProgramNetworkProviderData, loading: healthProgramNetworkProviderLoading } =
    useHealthProgramNetworkProviderBreadcrumbGetQuery({
      variables: {
        filter: { ids: [providerId] },
      },
      skip: !providerId,
    });

  const isLoading = loading || healthProgramNetworkProviderLoading;

  const networkName = data?.healthProgramNetworks?.edges?.[0]?.node?.name;
  const provider = healthProgramNetworkProviderData?.healthProgramNetworkProviders?.edges?.[0]?.node?.provider;
  const providerName = pickLocalizedValue(provider?.name, provider?.nameAr!);

  const breadcrumbTitle = t("Networks Providers");

  const healthProgramNetworkListBreadcrumb = useMemo(() => {
    return [
      {
        name: t("Networks"),
        route: healthProgramNetworksPaths.list.fullPath,
      },
      { name: networkName },
    ];
  }, [networkName, t]);

  const healthProgramNetworkProviderListBreadcrumb = useMemo(() => {
    return {
      name: t("Providers"),
      route: healthProgramNetworksPaths.providerList.fullPathWithParams({ networkId }),
    };
  }, [networkId, t]);

  const setHealthProgramNetworkProviderListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: healthProgramNetworkListBreadcrumb,
    });
  }, [breadcrumbTitle, healthProgramNetworkListBreadcrumb, setBreadCrumb]);

  const setHealthProgramNetworkProviderCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [...healthProgramNetworkListBreadcrumb, healthProgramNetworkProviderListBreadcrumb, { name: t("New") }],
    });
  }, [setBreadCrumb, breadcrumbTitle, healthProgramNetworkListBreadcrumb, healthProgramNetworkProviderListBreadcrumb, t]);

  const setHealthProgramNetworkProviderUpdateContainerBreadcrumb = useCallback(() => {
    if (providerId) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [...healthProgramNetworkListBreadcrumb, healthProgramNetworkProviderListBreadcrumb, { name: providerName }],
      });
    }
  }, [
    providerId,
    providerName,
    setBreadCrumb,
    breadcrumbTitle,
    healthProgramNetworkListBreadcrumb,
    healthProgramNetworkProviderListBreadcrumb,
  ]);

  const setHealthProgramNetworkProviderProfessionalListContainerBreadcrumb = useCallback(() => {
    if (providerId) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [
          ...healthProgramNetworkListBreadcrumb,
          healthProgramNetworkProviderListBreadcrumb,
          { name: providerName },
          { name: t("Professionals") },
        ],
      });
    }
  }, [
    providerId,
    providerName,
    setBreadCrumb,
    breadcrumbTitle,
    healthProgramNetworkListBreadcrumb,
    healthProgramNetworkProviderListBreadcrumb,
    t,
  ]);

  useEffect(() => {
    if (!isLoading) {
      if (containerType === "LIST") {
        setHealthProgramNetworkProviderListContainerBreadcrumb();
      } else if (containerType === "CREATE") {
        setHealthProgramNetworkProviderCreateContainerBreadcrumb();
      } else if (containerType === "UPDATE") {
        setHealthProgramNetworkProviderUpdateContainerBreadcrumb();
      } else if (containerType === "PROFESSIONAL_LIST") {
        setHealthProgramNetworkProviderProfessionalListContainerBreadcrumb();
      }
    }
  }, [
    isLoading,
    containerType,
    setHealthProgramNetworkProviderListContainerBreadcrumb,
    setHealthProgramNetworkProviderCreateContainerBreadcrumb,
    setHealthProgramNetworkProviderUpdateContainerBreadcrumb,
    setHealthProgramNetworkProviderProfessionalListContainerBreadcrumb,
  ]);
};
