import { Grid, ImageUploadController } from "@toolkit/ui";
import { useTranslation } from "@toolkit/i18n";
import React from "react";
import { useOidcUserProfile } from "@/shared/hooks/useOidcUserProfile";
import { useCustomFormContext } from "@toolkit/core";
import { IHealthProgramUpsertFormValues } from "../HealthProgramUpsert/HealthProgramUpsertFormSchema";

export const HealthProgramImagesForm = () => {
  const { t } = useTranslation("admin");

  const { accessToken } = useOidcUserProfile();

  const form = useCustomFormContext<IHealthProgramUpsertFormValues>();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ImageUploadController name={"images"} hasMultipleImages control={form.control} label={t("Images")} token={accessToken!} />
      </Grid>
    </Grid>
  );
};
