import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { healthProgramMemberListsPaths } from "../constants";

export const useSetHealthProgramMemberListsBreadcrumbs = (containerType: "LIST" | "CREATE" | "UPDATE", name?: string) => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  const breadcrumbTitle = t("Members Lists");

  const healthProgramMemberListListBreadcrumb = useMemo(() => {
    return {
      name: t("Members Lists"),
      route: healthProgramMemberListsPaths.list.fullPath,
    };
  }, [t]);

  const setHealthProgramMemberListListContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
    });
  }, [breadcrumbTitle, setBreadCrumb]);

  const setHealthProgramMemberListCreateContainerBreadcrumb = useCallback(() => {
    setBreadCrumb({
      title: breadcrumbTitle,
      values: [healthProgramMemberListListBreadcrumb, { name: t("New") }],
    });
  }, [breadcrumbTitle, setBreadCrumb, healthProgramMemberListListBreadcrumb, t]);

  const setHealthProgramMemberListUpdateContainerBreadcrumb = useCallback(() => {
    if (name) {
      setBreadCrumb({
        title: breadcrumbTitle,
        values: [healthProgramMemberListListBreadcrumb, { name }],
      });
    }
  }, [name, setBreadCrumb, breadcrumbTitle, healthProgramMemberListListBreadcrumb]);

  useEffect(() => {
    if (containerType === "LIST") {
      setHealthProgramMemberListListContainerBreadcrumb();
    } else if (containerType === "CREATE") {
      setHealthProgramMemberListCreateContainerBreadcrumb();
    } else if (containerType === "UPDATE") {
      setHealthProgramMemberListUpdateContainerBreadcrumb();
    }
  }, [
    containerType,
    setHealthProgramMemberListListContainerBreadcrumb,
    setHealthProgramMemberListCreateContainerBreadcrumb,
    setHealthProgramMemberListUpdateContainerBreadcrumb,
  ]);
};
