import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { healthProgramMemberListsRoute, healthProgramMemberListsPaths } from "./HealthProgramMemberListsPaths";
import {
  HealthProgramMemberListListContainer,
  HealthProgramMemberListCreateContainer,
  HealthProgramMemberListUpdateContainer,
} from "../containers";

export const healthProgramMembersListsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "health-program-members-lists-routes",
    text: i18n.t("Members Lists", { ns: "admin" }),
    route: healthProgramMemberListsRoute,
    subItems: [
      {
        id: "health-program-member-list-list-route",
        route: healthProgramMemberListsPaths.list.route,
        fullPath: healthProgramMemberListsPaths.list.fullPath,
        element: <HealthProgramMemberListListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "health-program-member-list-create-route",
        route: healthProgramMemberListsPaths.create.route,
        fullPath: healthProgramMemberListsPaths.create.fullPath,
        hidden: true,
        element: <HealthProgramMemberListCreateContainer />,
      },
      {
        id: "health-program-member-list-update-route",
        route: healthProgramMemberListsPaths.update.route,
        fullPath: healthProgramMemberListsPaths.update.fullPath,
        hidden: true,
        element: <HealthProgramMemberListUpdateContainer />,
      },
    ],
  };
};
