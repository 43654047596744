import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { i18n } from "@toolkit/i18n";

export const visitCancelFormSchema = z
  .object({
    reason: zodSchema.cancellationReason,
    otherReason: z.string().nullish(),
  })
  .superRefine((data, ctx) => {
    if (data?.reason?.value?.text?.toLowerCase() === "other" && !data?.otherReason) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t("Required", { ns: "admin" }),
        path: ["otherReason"],
      });
    }
  });

export type IVisitCancelFormValues = z.infer<typeof visitCancelFormSchema>;

export const visitCancelFormDefaultValues: Partial<IVisitCancelFormValues> = {
  reason: undefined,
  otherReason: undefined,
};
