import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { HealthProgramMember } from "@health/queries/types";
import { IHealthProgramMemberUpdateContainerParams } from "../../types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useHealthProgramMemberGetQuery, useHealthProgramMemberUpdateMutation } from "../../gql";
import { healthProgramMembersPaths } from "../../constants";
import { convertHealthProgramMemberFormValuesToBackEndValues } from "../../others";
import { useSetHealthProgramMembersBreadcrumbs } from "../../hooks";
import {
  HealthProgramMemberUpsertForm,
  IHealthProgramMemberUpsertFormEvent,
} from "../../forms/HealthProgramMemberUpsert/HealthProgramMemberUpsertForm";

export const HealthProgramMemberUpdateContainer = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();

  const { memberId } = useParams<IHealthProgramMemberUpdateContainerParams>();

  const [params] = useSearchParams();
  const memberListId = params?.get("memberListId");

  const { data, loading } = useHealthProgramMemberGetQuery({
    variables: {
      id: memberId!,
    },
    skip: !memberId,
    fetchPolicy: "no-cache",
  });

  const healthProgramMember = data?.healthProgramMember as HealthProgramMember;

  const [updateHealthProgramMember, { loading: isSubmitting }] = useHealthProgramMemberUpdateMutation({
    onCompleted: mutationData => {
      if (mutationData?.healthProgramMemberUpdate?.entity?.id) {
        succeeded(t("Member updated successfully"));
        navigate(healthProgramMembersPaths.list.fullPath);
      } else {
        failed(t("Member update failed"));
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const onHealthProgramMemberUpsertFormChange = (event: IHealthProgramMemberUpsertFormEvent) => {
    if (event.type === "SUBMIT") {
      const values = convertHealthProgramMemberFormValuesToBackEndValues(event.payload.values);

      updateHealthProgramMember({
        variables: {
          id: memberId!,
          input: { ...values, healthProgramMemberLists: memberListId ? [memberListId] : values?.healthProgramMemberLists },
        },
      });
    }
  };

  useSetHealthProgramMembersBreadcrumbs(
    "UPDATE",
    healthProgramMember ? `${healthProgramMember?.firstName} ${healthProgramMember?.lastName}` : ""
  );

  return (
    <HealthProgramMemberUpsertForm
      buttonLabel={t("Update")}
      healthProgramMember={healthProgramMember}
      memberListId={memberListId}
      isLoading={loading}
      isSubmitting={isSubmitting}
      onChange={onHealthProgramMemberUpsertFormChange}
    />
  );
};
