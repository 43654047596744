import { useCallback, useState } from "react";

export const useOpenState = (isOpen: boolean | undefined = false) => {
  const [open, setOpen] = useState<boolean>(isOpen);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleToggle = useCallback((): void => {
    setOpen(openState => !openState);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  return { open, handleOpen, handleClose, handleToggle };
};
