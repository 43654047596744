import { i18n } from "@toolkit/i18n";
import { IEnumToOptionsMap } from "@health/enum-options";

export enum CopayType {
  Fixed = "Fixed",
  Percentage = "Percentage",
}

export const copayTypeOptionsMap: IEnumToOptionsMap<CopayType> = {
  [CopayType.Fixed]: {
    key: CopayType.Fixed,
    get label() {
      return i18n.t("Amount", { ns: "admin" });
    },
    value: CopayType.Fixed,
  },
  [CopayType.Percentage]: {
    key: CopayType.Percentage,
    get label() {
      return i18n.t("Percentage", { ns: "admin" });
    },
    value: CopayType.Percentage,
  },
};

export const copayTypeOptions = Object.values(copayTypeOptionsMap);
