import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, ShowButton } from "@toolkit/ui";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { healthProgramNetworksPaths } from "@/pages/HealthProgramNetworks/constants";
import { IHealthProgramNetworkListNode } from "../../types";

export const useHealthProgramNetworksColumns = (): CustomTableColumnProps<IHealthProgramNetworkListNode>[] => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const onShowProvidersClick = useCallback(
    (networkId: string) => {
      navigate(healthProgramNetworksPaths.providerList.fullPathWithParams({ networkId }));
    },
    [navigate]
  );

  return useMemo(
    () => [
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
      },
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
      },
      {
        key: "payer",
        header: t("Payer"),
        accessor: ({ payer }) => pickLocalizedValue(payer?.name, payer?.nameAr),
      },
      {
        key: "providers",
        header: t("Providers"),
        accessor: ({ id }) => <ShowButton onClick={() => onShowProvidersClick(id)} />,
      },
    ],
    [onShowProvidersClick, t]
  );
};
