import { i18n } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "@/shared/components/Root/routeTypes";
import { ListViewIcon } from "@toolkit/ui";
import { visitsPaths, visitsRoute } from "./VisitsPaths";
import { VisitListContainer } from "../containers";

export const visitsRoutes: (navigate: NavigateFunction) => RouteItem = navigate => {
  return {
    id: "visits-routes",
    text: i18n.t("Visits", { ns: "admin" }),
    route: visitsRoute,
    icon: <ListViewIcon />,
    subItems: [
      {
        id: "visit-list-route",
        route: visitsPaths.list.route,
        fullPath: visitsPaths.list.fullPath,
        element: <VisitListContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
