/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramNetworkListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthProgramNetworkFilterInput>;
  sortBy?: Types.InputMaybe<Types.HealthProgramNetworkSortingInput>;
}>;


export type HealthProgramNetworkListQuery = { __typename?: 'Query', healthProgramNetworks?: { __typename?: 'HealthProgramNetworkCountableConnection', pageInfo: { __typename?: 'H_PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramNetworkCountableEdge', node: { __typename?: 'HealthProgramNetwork', id: string, name?: string | null, payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } }> } | null };


export const HealthProgramNetworkListDocument = gql`
    query HealthProgramNetworkList($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramNetworkFilterInput, $sortBy: HealthProgramNetworkSortingInput) {
  healthProgramNetworks(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        payer {
          id
          name
          nameAr
        }
      }
    }
  }
}
    `;

/**
 * __useHealthProgramNetworkListQuery__
 *
 * To run a query within a React component, call `useHealthProgramNetworkListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramNetworkListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramNetworkListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useHealthProgramNetworkListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramNetworkListQuery, HealthProgramNetworkListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramNetworkListQuery, HealthProgramNetworkListQueryVariables>(HealthProgramNetworkListDocument, options);
      }
export function useHealthProgramNetworkListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramNetworkListQuery, HealthProgramNetworkListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramNetworkListQuery, HealthProgramNetworkListQueryVariables>(HealthProgramNetworkListDocument, options);
        }
export type HealthProgramNetworkListQueryHookResult = ReturnType<typeof useHealthProgramNetworkListQuery>;
export type HealthProgramNetworkListLazyQueryHookResult = ReturnType<typeof useHealthProgramNetworkListLazyQuery>;
export type HealthProgramNetworkListQueryResult = Apollo.QueryResult<HealthProgramNetworkListQuery, HealthProgramNetworkListQueryVariables>;