/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CityListQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.CityFilterInput>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.CityOrder>;
}>;


export type CityListQuery = { __typename?: 'Query', cities?: { __typename?: 'CityCountableConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CityCountableEdge', node: { __typename?: 'City', id: string, name: string, nameAr?: string | null, area?: string | null, created: any } }> } | null };


export const CityListDocument = gql`
    query CityList($after: String, $before: String, $filter: CityFilterInput, $first: Int, $last: Int, $sortBy: CityOrder) {
  cities(
    after: $after
    before: $before
    filter: $filter
    first: $first
    last: $last
    sortBy: $sortBy
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        name
        nameAr
        area
        created
      }
    }
  }
}
    `;

/**
 * __useCityListQuery__
 *
 * To run a query within a React component, call `useCityListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useCityListQuery(baseOptions?: Apollo.QueryHookOptions<CityListQuery, CityListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CityListQuery, CityListQueryVariables>(CityListDocument, options);
      }
export function useCityListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CityListQuery, CityListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CityListQuery, CityListQueryVariables>(CityListDocument, options);
        }
export type CityListQueryHookResult = ReturnType<typeof useCityListQuery>;
export type CityListLazyQueryHookResult = ReturnType<typeof useCityListLazyQuery>;
export type CityListQueryResult = Apollo.QueryResult<CityListQuery, CityListQueryVariables>;