/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberListMemberListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.HealthProgramMemberFilterInput>;
}>;


export type HealthProgramMemberListMemberListQuery = { __typename?: 'Query', healthProgramMembers?: { __typename?: 'HealthProgramMemberCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'HealthProgramMemberCountableEdge', node: { __typename?: 'HealthProgramMember', id: string, firstName?: string | null, lastName?: string | null, phone?: string | null, nationalId?: string | null, sehacityId?: string | null, active?: boolean | null, membershipStart?: any | null, membershipEnd?: any | null, payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } }> } | null };


export const HealthProgramMemberListMemberListDocument = gql`
    query HealthProgramMemberListMemberList($first: Int, $last: Int, $after: String, $before: String, $filter: HealthProgramMemberFilterInput) {
  healthProgramMembers(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    edges {
      node {
        id
        firstName
        lastName
        phone
        nationalId
        sehacityId
        active
        payer {
          id
          name
          nameAr
        }
        membershipStart
        membershipEnd
      }
    }
  }
}
    `;

/**
 * __useHealthProgramMemberListMemberListQuery__
 *
 * To run a query within a React component, call `useHealthProgramMemberListMemberListQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberListMemberListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMemberListMemberListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHealthProgramMemberListMemberListQuery(baseOptions?: Apollo.QueryHookOptions<HealthProgramMemberListMemberListQuery, HealthProgramMemberListMemberListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMemberListMemberListQuery, HealthProgramMemberListMemberListQueryVariables>(HealthProgramMemberListMemberListDocument, options);
      }
export function useHealthProgramMemberListMemberListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMemberListMemberListQuery, HealthProgramMemberListMemberListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMemberListMemberListQuery, HealthProgramMemberListMemberListQueryVariables>(HealthProgramMemberListMemberListDocument, options);
        }
export type HealthProgramMemberListMemberListQueryHookResult = ReturnType<typeof useHealthProgramMemberListMemberListQuery>;
export type HealthProgramMemberListMemberListLazyQueryHookResult = ReturnType<typeof useHealthProgramMemberListMemberListLazyQuery>;
export type HealthProgramMemberListMemberListQueryResult = Apollo.QueryResult<HealthProgramMemberListMemberListQuery, HealthProgramMemberListMemberListQueryVariables>;